import { mapGetters, mapActions } from 'vuex';
import { ErrorHelper } from '../../../helpers/ErrorHelper';

export const BookingPaymentCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('BookingPayments', [
            'findBookingPaymentCache'
        ]),
        bookingId() {
            console.log('!!!OVERRIDE COMPUTED - bookingId!!!');
        },
        bookingPaymentCache() {
            return this.findBookingPaymentCache(this.bookingId);
        },
        bookingPayment() {
            return !!this.bookingPaymentCache ? this.bookingPaymentCache.value : null;
        },
        loadingBookingPayment() {
            return !!this.bookingPaymentCache && this.bookingPaymentCache.loading;
        }
    },
    watch: {
        bookingId() {
            this.refreshBookingPayment();
        }
    },
    methods: {
        ...mapActions('BookingPayments', [
            'getBookingPayment'
        ]),
        refreshBookingPayment() {
            if (!this.bookingPaymentCache || !!this.bookingPaymentCache.retry) {
                this.tryGetBookingPayment();
            }
        },
        tryGetBookingPayment() {
            const self = this;
            if (!self.bookingId) {
                return;
            }
            self.error = '';
            self.getBookingPayment(self.bookingId)
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshBookingPayment();
    }
}
