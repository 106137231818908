<template>
    <div class="main-page">
        <header class="header header-with-nav header-blue-mob">
            <div class="title-area">
                <h1 aria-expanded="false" aria-haspopup="true">
                    <i class="fa-duotone fa-user fa-1x"></i>
                    {{$t('Clients')}}
                    <small>({{clientCountString}})</small>
                </h1>
            </div>
            <div class="secondary-nav">
                <div class="search-field-static">
                    <div class="search-field">
                        <input v-model="searchTerm" aria-label="Search" autocomplete="off" class="search-field-input" id="client-search" :placeholder="$t('name, mobile, email, pet, breed')" spellcheck="false" type="search">
                        <button class="search-field-btn" type="submit">
                            <span v-if="searchingClients" class="inline-loader"></span>
                            <template v-else>
                                <i class="fas fa-search"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
            <div class="header-nav">
                <a class="d-block">
                    <button @click="gotoAddClient" class="mobile-header-btn" v-bind:disabled="fetchingClientAddAllowed">
                        <i class="fal fa-plus"></i>
                        <span class="btn-text sr-only">{{$t('Add client')}}</span>
                    </button>
                </a>
            </div>
            <div class="header-tools">
                <button @click="gotoAddClient" class="btn-add btn btn-secondary d-none d-md-block" v-bind:disabled="fetchingClientAddAllowed">
                    <i class="fas fa-plus"></i> {{$t('Add client')}}
                </button>
                <notification-bell></notification-bell>
            </div>
        </header>
        <div class="content">
            <!-- Table shows for mobile, desktop has own view -->
            <clients-mobile :search-key="searchKey" @skipTo="skipTo"></clients-mobile>
            <!-- Table shows for desktop, mobile has own view -->
            <table-desktop :page-number="clientPageNumber" @next="next" @previous="previous">
                <template #header>
                    <tr role="row">
                        <sort-header class="col-2" v-model="sortModel" key-name="firstName">{{$t('First Name')}}</sort-header>
                        <sort-header class="col-2" v-model="sortModel" :key-name="''">{{$t('Last Name')}}</sort-header>
                        <sort-header class="col-2" v-model="sortModel" key-name="telephoneMobile">{{$t('Mobile')}}</sort-header>
                        <sort-header class="col-2" v-model="sortModel" key-name="email">{{$t('Email')}}</sort-header>
                        <th v-if="sites.length > 1" class="col-2">{{$t('Site')}}</th>
                        <th class="col-2">{{$t('Pet(s)')}}</th>
                    </tr>
                </template>
                <template #rows>
                    <client-desk v-for="(client, index) in clients" :key="client.id" :client="client" :number="index"></client-desk>
                </template>
            </table-desktop>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { HashHelper } from '../../helpers/HashHelper';
    import { ClientCountCacheMixin } from '../Shared/ClientCountCacheMixin';
    import { ClientSearchMixinV2 } from '../Shared/ClientSearchMixinV2';
    import { ClientPetsSearchMixin } from './ClientPetsSearchMixin';
    import { ClientAddAllowedMixin } from '../Shared/ClientAddAllowedMixin';
    import { NotesSearchMixin } from '../Shared/Notes/NotesSearchMixin';
    import ClientItemDesktop from './ClientItemDesktop.vue';
    import NotificationBell from '../Shared/Notifications/NotificationBell.vue';
    import TableDesktop from '../Shared/Layout/TableDesktop.vue';
    import ClientsMobile from './ClientsMobile.vue';
    import TableSortableHeader from '../Shared/Layout/TableSortableHeader.vue';

    export default {
        name: 'Clients',
        mixins: [ClientPetsSearchMixin, ClientSearchMixinV2, ClientCountCacheMixin, ClientAddAllowedMixin, NotesSearchMixin],
        computed: {
            ...mapState('Sites', [
                'sites'
            ]),
            clientIds() {
                return this.clients.map(c => c.id);
            },
            searchKey() {
                return HashHelper.createStringHash(this.searchTerm + this.sortKey + this.ascending) + '-';
            },
            clientSearchKey() {
                return this.searchKey + this.skip + this.take;
            },
            clientCountString() {
                return this.clientCount ? this.clientCount.count : '-';
            },
            ownerIds() {
                return this.clientIds ? this.clientIds : [];
            },
            ownerTypeId() {
                return 2;
            }
        },
        created() {
            this.refreshClientSearch();
        },
        components: {
            'client-desk': ClientItemDesktop,
            'notification-bell': NotificationBell,
            'table-desktop': TableDesktop,
            'clients-mobile': ClientsMobile,
            'sort-header': TableSortableHeader
        }
    }
</script>
