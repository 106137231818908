import { ErrorHelper } from "../../helpers/ErrorHelper";
import { Api } from "../../services/Api";

const state = () => ({
    bookingTerms: []
})

const getters = {
    findBookingTerm: state => key => {
        return state.bookingTerms.find(bt => bt.key === key);
    }
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.bookingTerms.find(bt => bt.key === key);

        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.bookingTerms.push({ key: key, value: null, loading: !!loading });
        }
    },
    setBookingTerm(state, { key, value }) {
        const existing = state.bookingTerms.find(bt => bt.key === key);

        if (!!existing) {
            existing.value = value;
            existing.loading = false;
            existing.retry = false;
        } else {
            state.bookingTerms.push({ key: key, value: value, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.bookingTerms.find(bt => bt.key === key);

        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    fetchBookingTerm({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.get('BookingTerm')
                .then(response => {
                    commit('setBookingTerm', { key: key, value: response.data });
                    resolve(response);
                })
                .catch(error => {
                    if (ErrorHelper.isNotFound(error)) {
                        commit('setLoading', { key: key, loading: false });
                    } else {
                        commit('setRetry', key);
                        reject(error);
                    }
                })
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    addBookingTerm({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.post('BookingTerm', payload)
                .then(response => {
                    commit('setBookingTerm', { key: key, value: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
    updateBookingTerm({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('BookingTerm', payload)
                .then(response => {
                    commit('setBookingTerm', { key: key, value: response.data });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }));
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}