import { Api } from '../../services/Api';

const state = () => ({
    searchingClients: false,
    addingClient: false,
    clientCaches: []
})

const getters = {
    findClientCache: (state) => key => state.clientCaches.find(cc => cc.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.clientCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.clientCaches.push({ key: key, value: null, loading: false });
        }
    },
    setClients(state, clients) {
        for (var i = 0; i < clients.length; i++) {
            const client = clients[i];
            const existing = state.clientCaches.find(cc => cc.key === client.id);
            if (!!existing) {
                existing.value = client;
                existing.loading = false;
            } else {
                state.clientCaches.push({ key: client.id, value: client, loading: false });
            }
        }
    },
    setClient(state, client) {
        const existing = state.clientCaches.find(cc => cc.key === client.id);
        if (!!existing) {
            existing.value = client;
            existing.loading = false;
        } else {
            state.clientCaches.push({ key: client.id, value: client, loading: false });
        }
    },
    removeClient(state, id) {
        const existing = state.clientCaches.find(cc => cc.key === id);
        if (!!existing) {
            existing.value = null;
            existing.loading = false;
        }
    },
    setSearching(state, searching) {
        state.searchingClients = !!searching;
    },
    setAdding(state, adding) {
        state.addingClient = !!adding;
    },
    setRetry(state, key) {
        const existing = state.clientCaches.find(cc => cc.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getClient({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.get('Client/' + id)
                .then(response => {
                    commit('setClient', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', id);
                    reject(error);
                });
        });
    },
    searchClients({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('setSearching', true);
            Api.post('Client/Search', payload)
                .then(response => {
                    commit('setClients', response.data);
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setSearching', false));
        });
    },
    addClient({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit('setAdding', true);
            Api.post('Client', payload)
                .then(response => {
                    commit('setClient', response.data);
                    commit('ClientSearchesV2/clearClientSearches', null, { root: true });
                    commit('ApplicationEvents/triggerClientAdded', response.data, { root: true });
                    dispatch('ClientCount/fetchClientCount', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setAdding', false));
        });
    },
    updateClient({ commit }, { key, payload }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            Api.put('Client', payload)
                .then(response => {
                    commit('setClient', response.data);
                    commit('ClientSearchesV2/clearClientSearches', null, { root: true });
                    commit('PetSearchesV2/clearPetSearches', null, { root: true });
                    commit('ApplicationEvents/triggerClientUpdated', response.data, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: key, loading: false }))
        });
    },
    deleteClient({ commit, dispatch }, id) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: id, loading: true });
            Api.delete('Client/' + id)
                .then(response => {
                    commit('removeClient', id);
                    commit('ClientSearchesV2/clearClientSearches', null, { root: true });
                    commit('PetSearchesV2/clearPetSearches', null, { root: true });
                    commit('PetsV2/removePets', { key: id }, { root: true });
                    commit('ApplicationEvents/triggerClientDeleted', null, { root: true });
                    dispatch('ClientCount/fetchClientCount', null, { root: true });
                    resolve(response);
                })
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: id, loading: false }))
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
