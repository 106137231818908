<template>
    <div>
        <span v-if="loadingBookingPayment" class="inline-loader"></span>
        <div v-else-if="noPayment" class="alert-wrapper px-0">
            <div role="alert" class="alert alert-info alert-icon">
                <div class="alert-content">
                    <p>{{$t("This booking does not include payment.")}}</p>
                </div>
            </div>
        </div>
        <div v-else class="table-responsive">
            <table class="table table-striped ps-table table-hover">
                <thead>
                    <tr>
                        <th>{{$t('Description')}}</th>
                        <th>{{$t('Provider')}}</th>
                        <th>{{$t('Amount')}}</th>
                        <th>{{$t('Status')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{paymentType}}</td>
                        <td>{{providerType}}</td>
                        <td>{{currencySymbol}}{{amount.toFixed(2)}}</td>
                        <td>{{status}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { BookingPaymentCacheMixin } from "./BookingPaymentCacheMixin";
    import { CountryCacheMixin } from '../../Shared/Country/CountryCacheMixin';

    export default {
        name: 'BookingPaymentDetails',
        mixins: [BookingPaymentCacheMixin, CountryCacheMixin],
        props: {
            id: '',
        },
        computed: {
            bookingId() {
                return !!this.id ? this.id : '';
            },
            noPayment() {
                return !this.bookingPayment && !this.loadingBookingPayment
            },
            amount() {
                return !!this.bookingPayment ? this.bookingPayment.amount : 0;
            },
            currency() {
                return !!this.bookingPayment ? this.bookingPayment.currency : '';
            },
            status() {
                return !!this.bookingPayment ? this.bookingPayment.status.description : '';
            },
            paymentType() {
                return !!this.bookingPayment ? this.bookingPayment.paymentType.description : '';
            },
            currencySymbol() {
                const { currency, currencySymbol } = this.country || {};
                return currency === this.currency ? currencySymbol : `${this.currency} `;
            },
            providerType() {
                return !!this.bookingPayment ? this.bookingPayment.providerType.description : '';
            }
        }
    }
</script>
