<template>
    <full-calendar ref="fullCalendar" :options="config">
        <template #resourceLabelContent="{ resource }">
            <calendar-resource :resource="resource"></calendar-resource>
        </template>
        <template #eventContent="{ event }">
            <calendar-item :event="event"></calendar-item>
        </template>
    </full-calendar>
</template>

<script>
    import { DateDisplayMixin } from '../Shared/Time/DateDisplayMixin.js';
    import { TimeZoneMixin } from '../Shared/Time/Zone/TimeZoneMixin.js';
    import { StatusEmitterMixin } from "../../mixins/Shared/StatusEmitterMixin";
    import { CalendarHelper } from '../../helpers/CalendarHelper';
    import moment from 'moment';
    import FullCalendar from '@fullcalendar/vue';
    import momentTimezonePlugin from '@fullcalendar/moment-timezone';
    import bootstrapPlugin from '@fullcalendar/bootstrap';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
    import CalendarItem from './CalendarItem.vue';
    import CalendarResource from './CalendarResource.vue';

    export default {
        name: 'Calendarx',
        mixins: [DateDisplayMixin, TimeZoneMixin, StatusEmitterMixin],
        data() {
            return {
                calendar: {
                    next() { },
                    prev() { },
                    today() { },
                    gotoDate() { },
                    changeView() { }
                },
                loadingCalendarApi: false
            }
        },
        props: {
            events: Array,
            resources: Array,
            userSetting: {
                type: Object,
                required: true
            },
            siteSetting: {
                type: Object,
                required: true
            },
            displayResourceColor: true
        },
        computed: {
            businessHours() {
                const days = [0, 1, 2, 3, 4, 5, 6];
                return {
                    daysOfWeek: days.filter(d => this.siteSetting.hiddenDays.indexOf(d) < 0),
                    startTime: this.siteSetting.startHour,
                    endTime: this.siteSetting.endHour
                }
            },
            hiddenDays() {
                return this.siteSetting.hiddenDays;
            },
            firstDay() {
                return this.siteSetting.startDay;
            },
            nonBusinessHoursAmount() {
                return this.siteSetting.nonBusinessHoursAmount;
            },
            initialView() {
                return this.userSetting.scheduleView;
            },
            resourceOrder() {
                return this.userSetting.resourceOrder
            },
            config() {
                return {
                    ...this.configOptions,
                    ...this.eventHandlers
                }
            },
            configOptions() {
                return {
                    plugins: [momentTimezonePlugin, bootstrapPlugin, interactionPlugin, timeGridPlugin, resourceTimeGridPlugin, dayGridPlugin],
                    schedulerLicenseKey: process.env.VUE_APP_SCHEDULER_LICENSE_KEY,
                    timeZone: this.selectedTimeZoneId,
                    events: this.events,
                    resources: this.resources,
                    resourceOrder: this.resourceOrder,
                    editable: false,
                    selectable: true,
                    dayMaxEvents: true,
                    weekends: true,
                    height: "auto",
                    slotMinTime: this.gridStartTime,
                    slotMaxTime: this.gridEndTime,
                    initialView: this.initialView,
                    businessHours: this.businessHours,
                    hiddenDays: this.hiddenDays,
                    firstDay: this.firstDay,
                    nowIndicator: true,
                    allDaySlot: false,
                    datesSet: this.onDatesSet,
                    dateClick: this.onDateClick,
                    eventClick: this.onEventClick,
                    dayHeaderContent: (args) => {
                        return args.view.type === 'dayGridMonth' ?
                            args.text :
                            moment(args.date).format('ddd, ' + this.momentJSFormat);
                    },
                    loading: this.setLoadingCalendarApi,
                    eventDidMount: this.handleEventDidMount
                }
            },
            gridStartTime() {
                let startTimeHours = parseInt(this.businessHours.startTime.split(":")[0], 10);
                let startTimeMinutes = parseInt(this.businessHours.startTime.split(":")[1], 10);
                startTimeHours -= this.nonBusinessHoursAmount;
                if (startTimeHours < 0) {
                    startTimeHours = 0;
                    startTimeMinutes = 0;
                }
                const formattedHours = startTimeHours.toString().padStart(2, "0");
                const formattedMinutes = startTimeMinutes.toString().padStart(2, "0");
                return `${formattedHours}:${formattedMinutes}`;
            },
            gridEndTime() {
                let endTimeHours = parseInt(this.businessHours.endTime.split(":")[0], 10);
                let endTimeMinutes = parseInt(this.businessHours.endTime.split(":")[1], 10);
                endTimeHours += this.nonBusinessHoursAmount;
                if (endTimeHours > 24) {
                    endTimeHours = 24;
                    endTimeMinutes = 0;
                }
                const formattedHours = endTimeHours.toString().padStart(2, "0");
                const formattedMinutes = endTimeMinutes.toString().padStart(2, "0");
                return `${formattedHours}:${formattedMinutes}`;
            },
            status() {
                return {
                    loading: this.loadingCalendarApi
                }
            }
        },
        watch: {
            initialView(value) {
                this.changeView(value);
            }
        },
        methods: {
            onDatesSet(dates) {
                this.$emit('datesSet', dates);
            },
            onDateClick(info) {
                this.$emit('slotClick', info);
            },
            onEventClick(info) {
                this.$emit('eventClick', info);
            },
            next() {
                this.calendar.next();
                this.$emit('dateSet', this.calendar.getDate());
            },
            previous() {
                this.calendar.prev();
                this.$emit('dateSet', this.calendar.getDate());
            },
            today() {
                this.calendar.today();
                this.$emit('dateSet', this.calendar.getDate());
            },
            goto(date) {
                this.calendar.gotoDate(date);
                this.$emit('dateSet', this.calendar.getDate());
            },
            changeView(viewName) {
                this.calendar.changeView(viewName);
                this.$emit('viewChanged', viewName);
            },
            scrollToCurrentTime() {
                const now = new Date();
                const startOfHour = now.setMinutes(0, 0, 0);
                this.calendar.scrollToTime(startOfHour);
            },
            setLoadingCalendarApi(value) {
                this.loadingCalendarApi = !!value;
            },
            handleEventDidMount(info) {
                if (!this.calendar || !this.calendar.view) {
                    return;
                }
                const view = this.calendar.view;
                // Apply styles only if the view is 'dayGridMonth'
                if (view.type === 'dayGridMonth') {
                    if (info.event.extendedProps.appointment) {
                        const appointment = info.event.extendedProps.appointment;
                        const resourceId = appointment.resourceId;
                        const resource = this.resources.find(r => r.id === resourceId);
                        const statusId = appointment.status.id;
                        info.el.style.backgroundColor = this.displayResourceColor ? resource ? resource.eventColor || '#FFF' : '#FFF' : CalendarHelper.getAppointmentStatusColour(statusId);
                        info.el.style.borderLeft = `5px solid ${info.borderColor || 'transparent'}`;
                    } else if (info.event.extendedProps.staffEvent) {
                        const staffEvent = info.event.extendedProps.staffEvent;
                        info.el.style.backgroundColor = staffEvent.colour || '#FFF';
                    }
                }
            }
        },
        mounted() {
            this.calendar = this.$refs.fullCalendar.getApi();
            this.changeView(this.initialView);
            this.scrollToCurrentTime();
        },
        components: {
            FullCalendar,
            'calendar-item': CalendarItem,
            'calendar-resource': CalendarResource
        }
    }
</script>
