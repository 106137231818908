import { CacheHelper } from "../../helpers/CacheHelper";
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapGetters, mapActions } from 'vuex';

export const PaymentCustomersCacheMixin = {
    data() {
        return {
            error: ''
        }
    },
    computed: {
        ...mapGetters('PaymentCustomers', [
            'findPaymentCustomersCache'
        ]),
        clientId() {
            console.log('OVERRIDE COMPUTED - clientId');
        },
        customersFilterModel() {
            return {
                clientId: this.clientId
            }
        },
        customersFilterModelValid() {
            return !!this.customersFilterModel.clientId;
        },
        customersCacheKey() {
            return CacheHelper.objectCacheKey(this.customersFilterModel);
        },
        customersCache() {
            return this.findPaymentCustomersCache(this.customersCacheKey);
        },
        customers() {
            return !!this.customersCache ? this.customersCache.values : [];
        },
        loadingCustomers() {
            return !!this.customersCache && this.customersCache.loading;
        }
    },
    watch: {
        customersCacheKey() {
            this.refreshCustomers();
        }
    },
    methods: {
        ...mapActions('PaymentCustomers', [
            'findCustomers'
        ]),
        refreshCustomers() {
            if (!this.customersCache || !!this.customersCache.retry) {
                this.tryFindCustomers();
            }
        },
        tryFindCustomers() {
            const self = this;
            if (!self.customersFilterModelValid) {
                return;
            }
            self.error = '';
            self.findCustomers({ key: self.customersCacheKey, payload: self.customersFilterModel })
                .catch(error => self.error = ErrorHelper.getError(error));
        }
    },
    created() {
        this.refreshCustomers();
    }
}
