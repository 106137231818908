<template>
    <section class="group">
        <div class="title-divider d-flex w-100"></div>
        <div class="row form-group">
            <div class="col-md-12 text-center">
                <button @click="onCancel" type="button" class="btn btn-secondary">
                    <i class="fa fa-arrow-left"></i>
                    {{$t('Back')}}
                </button>
            </div>
        </div>
        <sumup-widget v-if="providerTypeId===1" :checkout="checkout" @completed="onComplete" @errored="onError"></sumup-widget>
    </section>
</template>

<script>
    import SumUpPaymentWidget from './SumUp/SumUpPaymentWidget.vue';

    export default {
        name: 'CheckoutProcess',
        props: {
            checkout: Object
        },
        computed: {
            providerTypeId() {
                return !!this.checkout ? this.checkout.providerType.id : 0;
            }
        },
        methods: {
            onCancel() {
                this.$emit('cancelled');
            },
            onComplete() {
                this.$emit('completed');
            },
            onError(message) {
                this.$emit('completed', message);
            }
        },
        components: {
            'sumup-widget': SumUpPaymentWidget
        }
    }
</script>
