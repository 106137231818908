import { Api } from '../../services/Api';

const state = () => ({
    bookingPaymentCaches: []
})

const getters = {
    findBookingPaymentCache: (state) => key => state.bookingPaymentCaches.find(bp => bp.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.bookingPaymentCaches.find(bp => bp.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.bookingPaymentCaches.push({ key: key, value: null, loading: false });
        }
    },
    setBookingPayment(state, bookingPayment) {
        const existing = state.bookingPaymentCaches.find(bp => bp.key === bookingPayment.bookingId);
        if (!!existing) {
            existing.value = bookingPayment;
            existing.loading = false;
        } else {
            state.bookingPaymentCaches.push({ key: bookingPayment.bookingId, value: bookingPayment, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.bookingPaymentCaches.find(bp => bp.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    getBookingPayment({ commit }, bookingId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: bookingId, loading: true });
            Api.get('Payment/Booking/' + bookingId)
                .then(response => {
                    commit('setBookingPayment', response.data);
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', bookingId);
                    reject(error);
                });
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
