<template>
    <tr>
        <td>
            <i v-if="iconClass" :class="iconClass"></i>
            <template v-else>
                <p>{{$t('Unknown')}}</p>
            </template>
        </td>
        <td class="text-center">{{cardNumber}}</td>
        <td class="text-center">
            <p v-bind:class="statusClass">{{ status }}</p>
        </td>
        <td class="text-left">
            <button v-if="active"
                    @click="displayModal"
                    class="btn btn-sm btn-outline-secondary"
                    style="width: max-content;"
                    type="button">
                <i class="fal fa-ban"></i>
                {{ $t('Deactivate') }}
            </button>
            <button ref="displayModalButton" type="button" :data-target="`#${computedDataTarget}`" data-toggle="modal" style="display: none"></button>
            <modal-dialog :id="computedDataTarget" :title="$t('Are you sure?')">
                <section class="rounded-card-white">
                    <error-banner v-if="error" :message="error"></error-banner>
                    <div class="alert alert-warning alert-icon mb-2" role="alert">
                        <div class="alert-content">
                            <div class="alert-content d-flex align-items-center">
                                <p>Would you like to deactivate this client's payment card?</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label>{{$t('Client Name')}}:</label>
                        </div>
                        <div class="col-md-8">
                            <p>{{fullName}}</p>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label>{{$t('Last 4 Digits')}}:</label>
                        </div>
                        <div class="col-md-8">
                            <p>{{cardNumber}}</p>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label>{{$t('Type')}}:</label>
                        </div>
                        <div class="col-md-8">
                            <i v-if="iconClass" :class="iconClass"></i>
                            <template v-else>
                                <p>{{$t('Unknown')}}</p>
                            </template>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label>{{$t('Status')}}:</label>
                        </div>
                        <div class="col-md-8">
                            <p v-bind:class="statusClass">{{ status }}</p>
                        </div>
                    </div>
                </section>
                <div class="d-flex justify-content-center">
                    <button @click="tryDeleteInstrument" type="button" class="btn btn-secondary mr-2" v-bind:disabled="loadingInstruments || !active">
                        {{$t('Yes')}}
                        <span v-if="loadingInstruments" class="inline-loader"></span>
                    </button>
                    <button ref="cancelButton" type="button" class="btn btn-outline-secondary" data-dismiss="modal">{{$t('No')}}</button>
                </div>
            </modal-dialog>
        </td>
    </tr>
</template>

<script>
    import { PaymentInstrumentDeleteMixin } from "../../Payments/PaymentInstrumentDeleteMixin";
    import { ClientCacheMixin } from "../../Shared/ClientCacheMixin";
    import ModalDialog from "../../Layout/ModalDialog.vue";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'PaymentInstrumentsItem',
        mixins: [PaymentInstrumentDeleteMixin, ClientCacheMixin],
        props: {
            id: String
        },
        methods: {
            displayModal() {
                this.$refs.displayModalButton.click();
            },
            afterDeletedInstrument() {
                this.tryUpdatePaymentInstruments();
                this.$refs.cancelButton.click();
            }
        },
        computed: {
            instrumentId() {
                return !!this.instrument ? this.instrument.id : '';
            },
            computedDataTarget() {
                return `payment-instrument-deactivate-${this.instrumentId}`;
            },
            customerId() {
                return !!this.instrument ? this.instrument.customerId : '';
            },
            cardType() {
                return !!this.instrument ? this.instrument.cardType : null;
            },
            cardTypeId() {
                return !!this.cardType ? this.cardType.id : 0;
            },
            last4Digits() {
                return !!this.instrument ? this.instrument.last4Digits : '';
            },
            cardNumber() {
                return !!this.last4Digits ? "**** **** **** " + this.last4Digits : ''; 
            },
            statusType() {
                return !!this.instrument ? this.instrument.statusType : null;
            },
            statusTypeId() {
                return !!this.statusType ? this.statusType.id : 0;
            },
            statusClass() {
                return {
                    'text-success': this.statusTypeId === 1,
                    'text-danger': this.statusTypeId !== 1
                };
            },
            status() {
                return !!this.statusType ? this.statusType.description : "Unknown";
            },
            active() {
                return !!this.statusType ? this.statusType.id == 1 : false;
            },
            iconClass() {
                const cardTypeIconMap = {
                    1: 'fa-brands fa-cc-visa',
                    2: 'fa-brands fa-cc-amex',
                    4: 'fa-brands fa-cc-diners-club',
                    5: 'fa-brands fa-cc-discover',
                    9: 'fa-brands fa-cc-jcb',
                    11: 'fa-brands fa-cc-mastercard',
                    12: 'fa-brands fa-cc-visa',
                };

                return this.cardType ? cardTypeIconMap[this.cardTypeId] ?? null : null;
            },
            clientId() {
                return !!this.id ? this.id : ''
            },
            fullName() {
                return !!this.client ? this.client.fullName : 'Unknown';
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'modal-dialog': ModalDialog
        }
    }
</script>
