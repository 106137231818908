<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label>{{$t('Appointments')}}</label>
        </div>
        <div class="col-md-8">
            <div class="form-group">
                <label>{{$t('Format')}}</label>
                <div class="dropdown">
                    <button aria-expanded="false" aria-haspopup="true" class="custom-select form-control text-left text-truncate" data-toggle="dropdown" type="button">
                        {{summary}}
                    </button>
                    <div aria-labelledby="dropdownMenuButton" class="custom-select-dropdown-menu dropdown-menu">
                        <div v-for="option in options" :key="option.id" @click="displayType=option.id" class="custom-select-dropdown-item dropdown-item pointer" v-bind:class="{ 'active selected': displayType===option.id }">
                            <span class="text-wrap">{{option.format}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>{{$t('Display resource color?')}}</label>
                <label class="ps-toggle-switch">
                    <input v-model="displayResourceColor" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                </label>
            </div>
            <div class="form-group">
                <label>{{$t('Display images?')}}</label>
                <label class="ps-toggle-switch">
                    <input v-model="displayImages" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                </label>
            </div>
            <div class="form-group">
                <label>{{$t('Display time?')}}</label>
                <label class="ps-toggle-switch">
                    <input v-model="displayTime" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                </label>
            </div>
            <div class="form-group">
                <label>{{$t('Display services?')}}</label>
                <label class="ps-toggle-switch">
                    <input v-model="displayOrders" class="toggle-checkbox" type="checkbox">
                    <div class="toggle-switch"></div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import { UserSettingUploadMixin } from "../../Shared/UserSettingUploadMixin";
    import { CalendarAppointmentSettingMixin } from "../../../mixins/Settings/User/CalendarAppointmentSettingMixin";
    import { mapState } from 'vuex';

    export default {
        name: 'CalendarAppointmentSettings',
        mixins: [UserSettingUploadMixin, CalendarAppointmentSettingMixin],
        data() {
            return {
                options: [
                    {
                        "id": 0,
                        "format": "Lassie (Jones)"
                    },
                    {
                        "id": 1,
                        "format": "Lassie Jones (Rough Collie)"
                    },
                    {
                        "id": 2,
                        "format": "Lassie (Rough Collie)"
                    },
                    {
                        "id": 3,
                        "format": "Lassie (Rough Collie) John Jones - 07123456789"
                    },
                    {
                        "id": 4,
                        "format": "Lassie (Rough Collie) John Jones - 07123456789 - 12 Aspenway, Farnham, GU88HH"
                    },
                    {
                        "id": 5,
                        "format": "Lassie Jones (Rough Collie) - Appointment Notes..."
                    }
                ]
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'saveScheduleSettingsEvent'
            ]),
            userSettingUploadEdit() {
                return {
                    displayType: this.displayType,
                    images: this.displayImages,
                    time: this.displayTime,
                    orders: this.displayOrders,
                    resourceColor: this.displayResourceColor
                }
            },
            summary() {
                return this.options.find(o => o.id === this.displayType).format;
            }
        },
        watch: {
            saveScheduleSettingsEvent() {
                this.uploadUserSetting();
            },
            loadingUserSettings(value) {
                this.$emit('input', value);
            }
        },
        methods: {
            getUserSettingValid() {
                return true;
            },
            refreshEditModel(userSettingValue) {
                this.displayType = userSettingValue.displayType;
                this.displayImages = !!userSettingValue.images;
                this.displayTime = !!userSettingValue.time;
                this.displayOrders = !!userSettingValue.orders;
                this.displayResourceColor = !!userSettingValue.resourceColor;
            }
        }
    }
</script>
