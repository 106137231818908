<template>
    <section class="d-flex flex-column align-items-center">
        <div class="title-divider d-flex w-100"></div>
        <div class="col-md-8 text-left">
            <error-banner v-if="error" :message="error" class="mb-1"></error-banner>
            <div class="alert alert-warning alert-icon mb-2" role="alert">
                <div class="alert-content">
                    <div class="alert-content d-flex align-items-center">
                        <p>{{ $t('Please review your checkout details before proceeding.') }}</p>
                    </div>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4">
                    <label for="title">{{$t('Status')}}:</label>
                </div>
                <div class="col-md-8">
                    {{ checkout.status.description }}
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4">
                    <label for="title">{{$t('Amount')}}:</label>
                </div>
                <div class="col-md-8">
                    {{ currencySymbol }}{{ checkout.amount.toFixed(2) }}
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4">
                    <label for="title">{{$t('Payment Card')}}:</label>
                </div>
                <div class="col-md-8">
                    {{cardNumber}}
                    <i v-if="iconClass" :class="iconClass"></i>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12 text-center">
                <button type="button" class="btn btn-secondary" @click="onCancel" v-bind:disabled="processing">
                    {{$t('Back')}}
                </button>
                <button type="button" class="btn btn-primary ml-2" @click="onProceed" v-bind:disabled="processing">
                    {{$t('Proceed')}}
                    <span v-if="processing" class="inline-loader"></span>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
    import { PaymentApi } from "../../services/PaymentApi";
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { CountryCacheMixin } from '../../components/Shared/Country/CountryCacheMixin';
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'CheckoutProcessPaymentInstrument',
        mixins: [CountryCacheMixin],
        props: {
            checkout: Object,
            customer: Object,
            instrument: Object
        },
        data() {
            return {
                processing: false
            }
        },
        computed: {
            cardType() {
                return !!this.instrument ? this.instrument.cardType : null;
            },
            cardTypeId() {
                return !!this.cardType ? this.cardType.id : 0;
            },
            last4Digits() {
                return !!this.instrument ? this.instrument.last4Digits : '';
            },
            cardNumber() {
                return !!this.last4Digits ? "**** **** **** " + this.last4Digits : '';
            },
            iconClass() {
                const cardTypeIconMap = {
                    1: 'fa-brands fa-cc-visa',
                    2: 'fa-brands fa-cc-amex',
                    4: 'fa-brands fa-cc-diners-club',
                    5: 'fa-brands fa-cc-discover',
                    9: 'fa-brands fa-cc-jcb',
                    11: 'fa-brands fa-cc-mastercard',
                    12: 'fa-brands fa-cc-visa',
                };
                return this.cardType ? cardTypeIconMap[this.cardTypeId] || 'fa-solid fa-credit-card' : null;
            },
            currencySymbol() {
                const { currency, currencySymbol } = this.country || {};
                return currency === this.checkout.currency ? currencySymbol : `${this.checkout.currency} `;
            },
            checkoutAmount() {
                return this.checkec
            },
            checkoutModel() {
                return {
                    customerId: !!this.customer ? this.customer.id : '',
                    instrumentId: !!this.instrument ? this.instrument.id : '',
                    checkoutId: !!this.checkout ? this.checkout.id : ''
                }
            },
            checkoutModelValid() {
                const model = this.checkoutModel;
                return !!model.customerId && !!model.instrumentId && !!model.checkoutId
            }
        },
        methods: {
            onCancel() {
                this.$emit('cancelled');
            },
            onComplete() {
                this.$emit('completed');
            },
            onError(message) {
                this.error = message;
               /* this.$emit('errored', message);*/
            },
            onProceed() {
                const self = this;
                if (!!self.processing || !self.checkoutModelValid) {
                    return;
                }
                self.processing = true;
                PaymentApi.post('PaymentInstrument/Checkout', self.checkoutModel)
                    .then(response => {
                        this.onComplete();
                    })
                    .catch(error => {
                        self.onError(ErrorHelper.getError(error));
                    })
                    .finally(() => self.processing = false);
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
