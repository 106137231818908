<template>
    <div>
        <div v-if="completed" class="alert alert-success alert-icon" role="alert">
            <div class="alert-content">
                <p>Client successfully added.</p>
            </div>
        </div>
        <template v-else>
            <error-banner v-if="error" :message="error"></error-banner>
            <client-name v-model="clientName" :validator="clientValidator"></client-name>
            <client-mobile v-model="telephoneMobile"></client-mobile>
            <client-email v-model="email" :validator="clientValidator"></client-email>
        </template>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { ClientAddMixin } from '../../Shared/ClientAddMixin';
    import { ClientOnboardingInviteValidMixin } from '../../Shared/ClientOnboardingInviteValidMixin';
    import ClientNameFields from '../../Clients/ClientNameFields.vue';
    import ClientMobileFieldV2 from '../../Clients/ClientMobileFieldV2.vue';
    import ClientEmailFieldV2 from '../../Clients/ClientEmailFieldV2.vue';
    import ErrorBanner from '../../ErrorValidation/ErrorBanner.vue';

    export default {
        name: 'NewClientV2',
        mixins: [ClientAddMixin, ClientOnboardingInviteValidMixin],
        data() {
            return {
                addedClientId: ''
            }
        },
        computed: {
            ...mapState('ApplicationEvents', [
                'addClientEvent',
                'clientAddedEvent',
            ]),
            completed() {
                return !!this.addedClientId;
            },
            status() {
                return {
                    error: !!this.error,
                    completed: !!this.completed,
                    validated: !!this.clientValidator.isValid,
                    loading: !!this.addingClient
                }
            },
            canAddClient() {
                return !this.addedClientId && !!this.clientValidator.isValid;
            },
            clientName: {
                get() {
                    return {
                        firstName: this.firstName,
                        lastName: this.lastName
                    };
                },
                set(value) {
                    this.firstName = value.firstName;
                    this.lastName = value.lastName;
                }
            }
        },
        watch: {
            addClientEvent() {
                this.onValidatedChanged();
            },
            clientAddedEvent(client) {
                this.addedClientId = client.id;
            },
            status() {
                this.$emit('input', this.status);
            }
        },
        methods: {
            onValidatedChanged() {
                // check model if valid
                this.clientValidator.check(this.clientAddModel);
                if (!this.canAddClient) {
                    return;
                }
                // check model and saving type if valid
                this.checkSavingType(this.clientAddModel, this.savingType);
                if (!this.isSavingTypeValid) {
                    return;
                }
                this.tryAddClient();
            }
        },
        components: {
            'client-name': ClientNameFields,
            'client-mobile': ClientMobileFieldV2,
            'client-email': ClientEmailFieldV2,
            'error-banner': ErrorBanner
        }
    }
</script>
