export const CalendarHelper = {
    getAppointmentStatusColour(id) {
        return id === 0 ? '#00BFFF' : id === 1 ? '#808080' : id === 2 ? '#85DB3E' : id === 3 ? '#C63763' : '#d3d3d3';
    },
    getAppointmentEvent(appointment, displayResourceColor) {
        const baseEvent = {
            id: appointment.id,
            appointment: appointment,
            start: appointment.startUtc,
            end: appointment.endUtc,
            resourceId: appointment.resourceId,
            borderColor: CalendarHelper.getAppointmentStatusColour(appointment.status.id)
        };

        if (!displayResourceColor) {
            baseEvent.backgroundColor = CalendarHelper.getAppointmentStatusColour(appointment.status.id);
        }

        return baseEvent;
    },
    getStaffEvent(staffEvent, colourSetting) {
        const baseEvent = {
            id: staffEvent.id,
            staffEvent: staffEvent,
            start: staffEvent.startUtc,
            end: staffEvent.endUtc,
            resourceId: staffEvent.resourceId
        };

        if (!!colourSetting.displayEventColour) {
            const eventTypeName = staffEvent.eventType.name;
            const color = colourSetting[eventTypeName] || colourSetting['Unknown'];
            baseEvent.backgroundColor = staffEvent.colour || color;
            baseEvent.borderColor = staffEvent.colour || color;
        }

        return baseEvent;
    },
    getResourceHourEvent(resourceHour) {
        const hours = !!resourceHour.hours ? resourceHour.hours : null;
        const startUtc = !!hours ? hours.startUtc : '';
        const endUtc = !!hours ? hours.endUtc : '';

        return {
            id: resourceHour.id,
            resourceHour: hours,
            start: startUtc,
            end: endUtc,
            resourceId: resourceHour.resourceId,
            className: 'day-off-pattern',
            borderColor: 'transparent'
        }
    }
}
