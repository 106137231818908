<template>
    <div v-if="displaySetting" class="form-group row">
        <div class="col-md-4">
            <slot></slot>
        </div>
        <div class="col-md-8">
            <div class="bootstrap-select form-control">
                <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                    {{selectedName}}
                </button>
                <div class="inner show">
                    <ul class="dropdown-menu">
                        <li v-for="option in options"
                            class="dropdown-item pointer"
                            :key="option.value"
                            @click="setPaymentModeId(option.value)"
                            v-bind:class="{'active': option.value === paymentModeId}">
                            {{$t(option.name)}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { PaymentOAuthsCacheMixin } from "../../Payments/PaymentOAuthsCacheMixin";
    import Tooltip from '../../Shared/Layout/Tooltip.vue';

    export default {
        name: 'SiteUpdateBookingPaymentMode',
        mixins: [PaymentOAuthsCacheMixin],
        props: {
            value: Number
        },
        data() {
            return {
                paymentModeId: 0,
                options: [
                    { name: 'No Payment', value: 0 },
                    { name: 'Store Card Only', value: 1 },
                    { name: 'Deposit Only', value: 2 },
                    { name: 'Auto Amount', value: 3 }
                ]
            }
        },
        computed: {
            selectedOption() {
                return this.options.find(o => o.value === this.paymentModeId);
            },
            selectedName() {
                return !!this.selectedOption ? this.selectedOption.name : `${this.$t('Please select')}...`;
            },
            displaySetting() {
                return this.paymentOAuths.length > 0 ? true : false;
            }
        },
        methods: {
            setPaymentModeId(value) {
                this.paymentModeId = value;
                this.$emit('input', value);
            }
        },
        created() {
            this.setPaymentModeId(this.value);
        },
        components: {
            'tooltip': Tooltip
        }
    }
</script>
