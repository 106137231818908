<template>
    <tbody>
        <tr v-if="!!error" :class="classType">
            <td colspan="12">
                <error-banner :message="error" class="mb-0"></error-banner>
            </td>
        </tr>
        <tr :class="classType">
            <td class="text-left d-flex">
                <img alt="Default" :src="provider.logoUrl" height="20">
                <div class="ml-2">{{ name }}</div>
            </td>
            <td><span v-if="customer">{{ createdLocalString }}</span></td>
            <td>{{ registered ? $t('Registered') : $t('Unregistered') }}</td>
            <td class="text-left">
                <div v-if="!customer">
                    <span v-if="addingCustomer" class="inline-loader"></span>
                    <button v-else-if="!addingCustomer && !loadingCustomers"
                            @click="tryAddCustomer"
                            class="btn btn-sm btn-outline-secondary"
                            style="width: max-content;"
                            type="button">
                        <i class="fal fa-user-plus"></i>
                        {{ $t('Register') }}
                    </button>
                </div>
            </td>
        </tr>
    </tbody>
</template>


<script>
    import moment from 'moment-timezone';
    import { PaymentCustomersAddMixin } from "../../Payments/PaymentCustomersAddMixin";
    import { DateDisplayMixin } from "../../Shared/Time/DateDisplayMixin";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'PaymentCustomerItem',
        mixins: [PaymentCustomersAddMixin, DateDisplayMixin],
        props: {
            provider: Object,
            number: Number,
            id: String
        },
        computed: {
            clientId() {
                return this.id
            },
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            },
            name() {
                return !!this.provider ? this.provider.name : '';
            },
            providerTypeId() {
                return !!this.provider ? this.provider.id : 0;
            },
            createdLocalString() {
                return !!this.customer ? moment(this.customer.createdUtc).format(this.momentJSFormat) : '';
            },
            registered() {
                return !!this.customer && !!this.customersCache 
            }
        },
        components: {
            'error-banner': ErrorBanner
        }
    }
</script>
