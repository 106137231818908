<template>
    <section class="mb-3">
        <div aria-controls="collapseOne" class="accordion-header" data-target="#collapse-mpo-10" data-toggle="collapse">
            <i class="fas fa-credit-card mr-2"></i>
            Checkout Limit
            <i class="far fa-chevron-down ml-auto"></i>
        </div>
        <div class="collapse" id="collapse-mpo-10">
            <div class="card card-body">
                <error-banner v-if="error" :message="error"></error-banner>
                <div v-if="completed" class="alert alert-success alert-icon" role="alert">
                    <div class="alert-content">
                        <p>Checkout Limit Saved!</p>
                    </div>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span>x</span>
                    </button>
                </div>
                <div class="form-group">
                    <label>Site ID</label>
                    <input v-model="siteId" class="form-control" type="text">
                    <validation-message v-if="!!siteIdMessage" :message="siteIdMessage"></validation-message>
                </div>
                <div class="form-group">
                    <label>Amount</label>
                    <input v-model="amount" class="form-control text-right" type="number">
                    <validation-message v-if="!!amountMessage" :message="amountMessage"></validation-message>
                </div>
                <div class="form-group">
                    <label>Currency</label>
                    <input v-model="currency" class="form-control" type="text">
                    <validation-message v-if="!!currencyMessage" :message="currencyMessage"></validation-message>
                </div>
                <div class="form-group">
                    <label>{{$t('Provider')}}</label>
                    <div class="bootstrap-select form-control">
                        <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                            {{selectedName}}
                        </button>
                        <div class="inner show">
                            <ul class="dropdown-menu">
                                <li v-for="paymentProvider in paymentProviders"
                                    class="dropdown-item pointer"
                                    :key="paymentProvider.id"
                                    @click="setProviderTypeId(paymentProvider.id)"
                                    v-bind:class="{'active': paymentProvider.id === providerTypeId}">
                                    {{paymentProvider.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <validation-message v-if="!!providerTypeMessage" :message="providerTypeMessage"></validation-message>
                </div>
                <div class="form-group">
                    <button @click="submit" class="btn btn-primary" type="button" v-bind:disabled="loading">
                        Save
                        <span v-if="loading" class="inline-loader"></span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { ErrorHelper } from "../../helpers/ErrorHelper";
    import { PaymentApi } from "../../services/PaymentApi";
    import { PaymentProvidersCacheMixin } from "../Payments/PaymentProvidersCacheMixin";
    import ErrorBanner from "../ErrorValidation/ErrorBanner.vue";
    import ValidationMessage from "../ErrorValidation/ValidationMessage.vue";

    export default {
        name: 'SystemAdminCheckoutLimit',
        mixins: [PaymentProvidersCacheMixin],
        data() {
            return {
                siteId: '',
                amount: 1,
                currency: '',
                providerTypeId: 0,
                loading: false,
                completed: false,
                error: '',
                siteIdMessage: '',
                amountMessage: '',
                currencyMessage: '',
                providerTypeMessage: ''
            }
        },
        computed: {
            checkoutLimitAddModel() {
                return {
                    siteId: this.siteId,
                    amount: parseInt(this.amount),
                    currency: this.currency,
                    providerTypeId: this.providerTypeId
                }
            },
            checkoutLimitAddModelValid() {
                const model = this.checkoutLimitAddModel;
                return !!model.siteId && model.amount >= 1 && !!model.currency && model.providerTypeId > 0;
            },
            selectedOption() {
                return this.paymentProviders.length > 0 ? this.paymentProviders.find(pp => pp.id === this.providerTypeId) : null;
            },
            selectedName() {
                return !!this.selectedOption ? this.selectedOption.name : `${this.$t('Please select')}...`;
            }
        },
        methods: {
            submit() {
                const self = this;
                self.completed = false;
                self.error = '';
                self.validate();
                if (!self.checkoutLimitAddModelValid) {
                    return;
                }
                self.loading = true;
                PaymentApi.post('CheckoutLimit', self.checkoutLimitAddModel)
                    .then(() => {
                        self.completed = true;
                    })
                    .catch(error => self.error = ErrorHelper.getError(error))
                    .finally(() => self.loading = false);
            },
            validate() {
                const self = this;
                const model = self.checkoutLimitAddModel
                self.siteIdMessage = !!model.siteId ? '' : 'Please enter Site ID.';
                self.amountMessage = model.amount >= 1 ? '' : 'Minimum amount required is 1.';
                self.currencyMessage = !!model.currency ? '' : 'Please enter currency.';
                self.providerTypeMessage = model.providerTypeId >= 1 ? '' : 'Please select a payment provider.';
            },
            setProviderTypeId(value) {
                this.providerTypeId = value
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'validation-message': ValidationMessage
        }
    }
</script>
