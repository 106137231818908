<template>
    <section class="group">
        <error-banner v-if="error" :message="error"></error-banner>
        <checkout-provider v-model="providerTypeId" :validation="checkoutValidator.providerTypeId"></checkout-provider>
        <checkout-amount v-model="amount" :validation="checkoutValidator.amount"></checkout-amount>
        <div class="row form-group">
            <div class="col-md-8 offset-md-4">
                <button @click="tryAddCheckout" v-bind:disabled="loadingCheckouts" type="button" class="btn btn-primary">
                    Create
                    <span v-if="loadingCheckouts" class="inline-loader"></span>
                </button>
            </div>
        </div>
        <table-mobile v-if="displayCheckouts">
            <checkout-item-mobile v-for="checkout in checkouts" :key="checkout.id" :payment="checkout" :date-format="momentJSFormat" @process="onProcess"></checkout-item-mobile>
        </table-mobile>
        <table-desktop v-if="displayCheckouts">
            <template #header>
                <tr>
                    <th>Provider</th>
                    <th>Date</th>
                    <th style="text-align: right">Amount ({{currencySymbol}})</th>
                    <th style="text-align: center">Status</th>
                    <th></th>
                    <th></th>
                </tr>
            </template>
            <template #body>
                <checkout-item-desktop v-for="(checkout, index) in checkouts" :key="checkout.id" :payment="checkout" :is-refunder="isRefunder" :id="clientId"
                                       :date-format="momentJSFormat" :number="index" @process="onProcess" @error="onError"></checkout-item-desktop>
            </template>
        </table-desktop>
        <template v-if="instrument">
            <checkout-process-payment-instrument v-if="processing"
                                                 :checkout="processing"
                                                 :instrument="instrument" 
                                                 :customer="customer"
                                                 @cancelled="onCancel" 
                                                 @completed="onComplete">
            </checkout-process-payment-instrument>
        </template>
        <template v-else>
            <checkout-process v-if="processing" :checkout="processing" @cancelled="onCancel" @completed="onComplete"></checkout-process>
        </template>
    </section>
</template>

<script>
    import { mapActions } from 'vuex';
    import { ErrorHelper } from '../../helpers/ErrorHelper';
    import { DateDisplayMixin } from '../Shared/Time/DateDisplayMixin';
    import { PaymentCheckoutsAddMixin } from './PaymentCheckoutsAddMixin';
    import { PaymentCustomersCacheMixin } from './PaymentCustomersCacheMixin';
    import CheckoutListItemDesktop from './CheckoutListItemDesktop.vue';
    import CheckoutProviderSelect from './CheckoutProviderSelect.vue';
    import ProductAmountInput from '../Products/ProductAmountInput.vue';
    import CheckoutProcess from './CheckoutProcess.vue';
    import TableMobile from '../Shared/Layout/TableMobile.vue';
    import CheckoutListItemMobile from './CheckoutListItemMobile.vue';
    import ErrorBanner from '@/components/ErrorValidation/ErrorBanner.vue';
    import TableDesktopNoBody from '../Shared/Layout/TableDesktopNoBody.vue';
    import CheckoutProcessPaymentInstrument from './CheckoutProcessPaymentInstrument.vue'

    export default {
        name: 'CheckoutList',
        mixins: [PaymentCheckoutsAddMixin, DateDisplayMixin, PaymentCustomersCacheMixin],
        props: {
            id: String,
            reference: String,
            isRefunder: Boolean
        },
        data() {
            return {
                processing: null,
                instrument: null,
                customer: null
            }
        },
        computed: {
            clientId() {
                return this.id
            },
            displayCheckouts() {
                return this.checkouts.length > 0 && !this.processing;
            },
            checkoutsReference() {
                return this.reference;
            }
        },
        methods: {
            ...mapActions('PaymentCheckouts', [
                'updateCheckout'
            ]),
            onProcess(checkout, instrument, customer) {
                this.error = '';
                this.instrument = instrument;
                this.customer = customer
                this.processing = checkout;
            },
            onCancel() {
                this.tryUpdateCheckout();
                this.resetData();
            },
            onComplete(message) {
                this.error = !!message ? message : '';
                this.tryUpdateCheckout();
                this.resetData();
            },
            onError(message) {
                this.error = !!message ? message : '';
            },
            tryUpdateCheckout() {
                const self = this;
                if (!self.processing) {
                    return;
                }
                self.error = '';
                self.updateCheckout({ key: self.checkoutsReference, id: self.processing.id })
                    .catch(error => self.error = ErrorHelper.getError(error));
            },
            resetData() {
                this.instrument = null;
                this.customer = null
                this.processing = null;
            }
        },
        components: {
            'checkout-provider': CheckoutProviderSelect,
            'checkout-amount': ProductAmountInput,
            'checkout-item-desktop': CheckoutListItemDesktop,
            'checkout-item-mobile': CheckoutListItemMobile,
            'table-desktop': TableDesktopNoBody,
            'table-mobile': TableMobile,
            'checkout-process': CheckoutProcess,
            'checkout-process-payment-instrument': CheckoutProcessPaymentInstrument,
            'error-banner': ErrorBanner
        }
    }
</script>
