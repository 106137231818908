<template>
    <section class="mb-3">
        <div aria-expanded="false" class="accordion-header collapsed" data-target="#collapse2a" data-toggle="collapse">
            {{$t('Total')}}
            <div class="total-figure ml-auto">
                {{totalString}}
            </div>
            <i class="far fa-chevron-down ml-3"></i>
        </div>
        <div class="collapse" id="collapse2a">
            <div class="card card-body">
                <fieldset :disabled="disabled">
                    <order-item v-for="(orderItem, index) in orderItems" v-model="orderItems" :index="index" :key="index"></order-item>
                    <section class="group">
                        <div class="row form-group">
                            <div class="col-md-4">
                                <label>{{$t('Payment type')}}</label>
                            </div>
                            <payment-method v-model="paymentMethodId"></payment-method>
                        </div>
                    </section>
                </fieldset>
            </div>
        </div>
    </section>
</template>

<script>
    import { AppointmentTotalMixin } from '../../Shared/AppointmentTotalMixin.js';
    import AppointmentOverviewOrderItem from "./AppointmentOverviewOrderItem.vue";
    import AppointmentOverviewPaymentMethod from './AppointmentOverviewPaymentMethod.vue';

    export default {
        name: 'AppointmentOverviewTotal',
        mixins: [AppointmentTotalMixin],
        props: {
            value: Object,
            disabled: Boolean
        },
        computed: {
            paymentMethodId: {
                get() {
                    return this.value.paymentMethodId;
                },
                set(value) {
                    this.$emit('input', {
                        paymentMethodId: value,
                        orderItems: this.value.orderItems
                    });
                }
            },
            orderItems: {
                get() {
                    return this.value.orderItems;
                },
                set(value) {
                    this.$emit('input', {
                        paymentMethodId: this.value.paymentMethodId,
                        orderItems: value
                    });
                }
            }
        },
        components: {
            'order-item': AppointmentOverviewOrderItem,
            'payment-method': AppointmentOverviewPaymentMethod
        }
    }
</script>
