/* eslint-disable */
import { ErrorHelper } from '@/helpers/ErrorHelper';
import { mapActions } from 'vuex';

export const BookingApproveMixin = {
    props: {
        clientId: '',
        scheduleId: ''
    },
    data() {
        return {
            error: ''
        }
    },
    computed: {
        bookingId() {
            console.log('!!!OVERRIDE COMPUTED - bookingId!!!');
        },
        bookingUpdateModel() {
            return {
                id: this.bookingId,
                clientId: this.clientId,
                scheduleId: this.scheduleId
            }
        },
        bookingUpdateModelValid() {
            const model = this.bookingUpdateModel;
            return !!model.id && !!model.clientId && !!model.scheduleId
        }
    },
    methods: {
        ...mapActions('Bookings', [
            'approveBooking'
        ]),
        tryApproveBooking() {
            const self = this;
            if (!self.bookingUpdateModelValid) {
                return;
            }
            self.error = '';
            self.approveBooking({ id: self.bookingId, payload: self.bookingUpdateModel })
                .then(response => self.afterApproveBooking(response.data))
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterApproveBooking() {
            //override if necessary
        }
    }
}
