<template>
    <div>
        <template v-if="subscriptionFeatureAllowed">
            <span v-if="loadingCustomers" class="inline-loader"></span>
            <div v-if="!displayTable && !loadingCustomers" class="alert alert-info alert-icon" role="alert">
                <div class="alert-content">
                    <p>{{$t('Client is not registered with a payment provider. Please register in the Payment Providers section.')}}</p>
                </div>
            </div>
            <template v-else-if="!loadingCustomers">
                <error-banner v-if="error" :message="error"></error-banner>
                <template v-if="paymentInstrumentsEnabled">
                    <div v-for="customer in customers" :key="customer.id">
                        <payment-instruments-provider :customer="customer"></payment-instruments-provider>
                    </div>
                </template>
                <template v-else>
                    <div role="alert" class="alert alert-info alert-icon">
                        <div class="alert-content">
                            <p>{{$t('Payment instruments are currently unavailable.')}}</p>
                        </div>
                    </div>
                </template>
            </template>
        </template>
        <template v-else>
            <div role="alert" class="alert alert-info alert-icon">
                <div class="alert-content">
                    <p>{{$t('Feature not available on your current subscription.')}}</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { PaymentCustomersCacheMixin } from "../../Payments/PaymentCustomersCacheMixin";
    import { PaymentInstrumentSettingsCacheMixin } from "../../Payments/PaymentInstrumentSettingsCacheMixin";
    import { SubscriptionFeatureMixin } from '../../../mixins/Subscriptions/SubscriptionFeatureMixin';
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";
    import PaymentInstrumentsProvider from "./PaymentInstrumentsProvider.vue"

    export default {
        name: 'PaymentInstruments',
        mixins: [PaymentCustomersCacheMixin, PaymentInstrumentSettingsCacheMixin, SubscriptionFeatureMixin],
        props: {
            id: String
        },
        computed: {
            clientId() {
                return this.id
            },
            displayTable() {
                return !!this.customers.length > 0;
            },
            computedFeatureKey() {
                return 'payments'
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'payment-instruments-provider': PaymentInstrumentsProvider
        }
    }
</script>
