import vue from 'vue';
import vuex from 'vuex';
import Account from './Modules/Account';
import WeatherForecast from './Modules/WeatherForecast';
import Authentication from './Modules/Authentication';
import Clients from './Modules/Clients';
import PetsV2 from './Modules/PetsV2';
import Notes from './Modules/Notes';
import Addresses from './Modules/Addresses';
import AddressSearches from './Modules/AddressSearches';
import NotificationSettings from './Modules/NotificationSettings';
import AppointmentSearches from './Modules/AppointmentSearches';
import Histories from './Modules/Histories';
import Contacts from './Modules/Contacts';
import ClientSearchesV2 from './Modules/ClientSearchesV2';
import BreedSearches from './Modules/BreedSearches';
import Breeds from './Modules/Breeds';
import Weights from './Modules/Weights';
import Documents from './Modules/Documents';
import Resources from './Modules/Resources';
import Settings from './Modules/Settings';
import PetSearchesV2 from './Modules/PetSearchesV2';
import ProductSearches from './Modules/ProductSearches';
import ClientCount from './Modules/ClientCount';
import ResourceCount from './Modules/ResourceCount';
import SiteCount from './Modules/SiteCount';
import ApplicationEvents from './Modules/ApplicationEvents';
import Communications from './Modules/Communications';
import Signatures from './Modules/Signatures';
import Contracts from './Modules/Contracts';
import ContractTemplates from './Modules/ContractTemplates';
import UserSettings from './Modules/UserSettings';
import PetProducts from './Modules/PetProducts';
import AppointmentScheduleUpload from './Modules/AppointmentScheduleUpload';
import AppointmentSchedules from './Modules/AppointmentSchedules';
import Taxes from './Modules/Taxes';
import ScheduleFilter from './Modules/ScheduleFilter';
import Appointments from './Modules/Appointments';
import Orders from './Modules/Orders';
import Countries from './Modules/Countries';
import Unavailabilities from './Modules/Unavailabilities';
import OpeningHours from './Modules/OpeningHours';
import WorkingHours from './Modules/WorkingHours';
import OneOffHours from './Modules/OneOffHours';
import Sites from './Modules/Sites';
import Species from './Modules/Species';
import CountryOptionSearches from './Modules/CountryOptionSearches';
import CountryOptions from './Modules/CountryOptions';
import UserClaims from './Modules/UserClaims';
import Employees from './Modules/Employees';
import EmployeeSearches from './Modules/EmployeeSearches';
import UserResources from './Modules/UserResources';
import Events from './Modules/Events';
import EventSearches from './Modules/EventSearches';
import EventTypes from './Modules/EventTypes';
import AccountSettings from './Modules/AccountSettings';
import ContractTemplateHtmls from './Modules/ContractTemplateHtmls';
import ContractTemplateTags from './Modules/ContractTemplateTags';
import ContractTemplateSites from './Modules/ContractTemplateSites';
import SmsTemplates from './Modules/SmsTemplates';
import CommunicationTypes from './Modules/CommunicationTypes';
import SmsTemplateTags from './Modules/SmsTemplateTags';
import EmailTemplateTags from './Modules/EmailTemplateTags';
import Images from './Modules/Images';
import ImageSettings from './Modules/ImageSettings';
import Logos from './Modules/Logos';
import LogoSettings from './Modules/LogoSettings';
import TimeZones from './Modules/TimeZones';
import TagTypes from './Modules/TagTypes';
import Tags from './Modules/Tags';
import DateFormatSettings from './Modules/DateFormatSettings';
import DateFormatOptions from './Modules/DateFormatOptions';
import TimeFormatSettings from './Modules/TimeFormatSettings';
import TimeFormatOptions from './Modules/TimeFormatOptions';
import PostcodeSearches from './Modules/PostcodeSearches';
import Products from './Modules/Products';
import CampaignSettings from './Modules/CampaignSettings';
import EmailTemplateSettings from './Modules/EmailTemplateSettings';
import SmsSettings from './Modules/SmsSettings';
import PetDurations from './Modules/PetDurations';
import CountryFormats from './Modules/CountryFormats';
import AppointmentScheduleOrders from './Modules/AppointmentScheduleOrders';
import ConflictSearches from './Modules/ConflictSearches';
import AppointmentAggregateSearches from './Modules/AppointmentAggregateSearches';
import OrderAggregateSearches from './Modules/OrderAggregateSearches';
import ClientAggregateSearches from './Modules/ClientAggregateSearches';
import BreedAggregateSearches from './Modules/BreedAggregateSearches';
import OrderItemAggregateSearches from './Modules/OrderItemAggregateSearches';
import ClientVisitAggregateSearches from './Modules/ClientVisitAggregateSearches';
import ReportTypes from './Modules/ReportTypes';
import Reports from './Modules/Reports';
import ReportCsvs from './Modules/ReportCsvs';
import ReportSettings from './Modules/ReportSettings';
import Notifications from './Modules/Notifications';
import ReminderSettings from './Modules/ReminderSettings';
import AppointmentCounts from './Modules/AppointmentCounts';
import PaymentProviders from './Modules/PaymentProviders';
import PaymentOAuths from './Modules/PaymentOAuths';
import PaymentCheckouts from './Modules/PaymentCheckouts';
import PaymentMethods from './Modules/PaymentMethods';
import StripoTemplates from './Modules/StripoTemplates';
import Campaigns from './Modules/Campaigns';
import SmsCampaignTemplates from './Modules/SmsCampaignTemplates';
import CampaignTags from './Modules/CampaignTags';
import PaymentClientCheckouts from './Modules/PaymentClientCheckouts';
import PaymentTokens from './Modules/PaymentTokens';
import AppointmentDeposits from './Modules/AppointmentDeposits';
import AppointmentGroupSearches from './Modules/AppointmentGroupSearches';
import AppointmentGroups from './Modules/AppointmentGroups';
import PaymentStatuses from './Modules/PaymentStatuses';
import PaymentCheckoutSearches from './Modules/PaymentCheckoutSearches';
import PaymentApiUser from './Modules/PaymentApiUser';
import PaymentApiUserSearches from './Modules/PaymentApiUserSearches';
import PaymentUsers from './Modules/PaymentUsers';
import EmailCampaignTemplates from './Modules/EmailCampaignTemplates';
import EmailMarketingStripoDefaults from './Modules/EmailMarketingStripoDefaults';
import EmailMarketingTemplates from './Modules/EmailMarketingTemplates';
import EmailMarketingDefaultSubjects from './Modules/EmailMarketingDefaultSubjects';
import Subscription from './Modules/Subscription';
import StripoDefaultTemplateSettings from './Modules/StripoDefaultTemplateSettings';
import StripoDefaultTemplateBundleSettings from './Modules/StripoDefaultTemplateBundleSettings';
import PurchasedCampaignTemplates from './Modules/PurchasedCampaignTemplates';
import StripoDefaultTemplates from './Modules/StripoDefaultTemplates';
import SubscriptionUpgradeTypes from './Modules/SubscriptionUpgradeTypes';
import SubscriptionUpgradeFeatures from './Modules/SubscriptionUpgradeFeatures';
import SubscriptionUpgrade from './Modules/SubscriptionUpgrade';
import SubscriptionUpgradeSettings from './Modules/SubscriptionUpgradeSettings';
import SubscriptionSettings from './Modules/SubscriptionSettings';
import SubscriptionEntityPayments from './Modules/SubscriptionEntityPayments';
import ClientOnboardingInvites from './Modules/ClientOnboardingInvites';
import SubscriptionPaymentFailures from './Modules/SubscriptionPaymentFailures';
import SubscriptionPayments from './Modules/SubscriptionPayments';
import CreditPacks from './Modules/CreditPacks';
import CreditPackSettings from './Modules/CreditPackSettings';
import PetProductsV2 from './Modules/PetProductsV2';
import BreedProducts from './Modules/BreedProducts';
import BreedProductSearches from './Modules/BreedProductSearches';
import BreedProductGroups from './Modules/BreedProductGroups';
import ServiceProductSearches from './Modules/ServiceProductSearches';
import ServiceProductGroups from './Modules/ServiceProductGroups';
import ClientMessages from './Modules/ClientMessages';
import ClientLogs from './Modules/ClientLogs';
import SubscriptionPaymentSearches from './Modules/SubscriptionPaymentSearches';
import CreditPackSearches from './Modules/CreditPackSearches';
import PaymentReceipts from './Modules/PaymentReceipts';
import Referrers from './Modules/Referrers';
import ReferralRewardSettings from './Modules/ReferralRewardSettings';
import BreedCount from './Modules/BreedCount';
import Migrations from './Modules/Migrations';
import BookingCount from './Modules/BookingCount';
import Bookings from './Modules/Bookings';
import BookingSearches from './Modules/BookingSearches';
import BookingSettings from './Modules/BookingSettings';
import BookingTerms from './Modules/BookingTerms'
import BookingHistories from './Modules/BookingHistories';
import BookingStatuses from './Modules/BookingStatuses';
import ClientUsers from './Modules/ClientUsers';
import BookingSuggestions from './Modules/BookingSuggestions';
import BookingSuggestionCommunications from './Modules/BookingSuggestionCommunications'
import AccountMigrationAllowed from './Modules/AccountMigrationAllowed';
import CommunicationSearches from './Modules/CommunicationSearches'
import ServiceProductCount from './Modules/ServiceProductCount';
import EmployeeCount from './Modules/EmployeeCount';
import SmsUnitCost from './Modules/SmsUnitCost';
import SmsCalculatorSettings from './Modules/SmsCalculatorSettings';
import SubscriptionTypes from './Modules/SubscriptionTypes';
import SmsDefaults from './Modules/SmsDefaults';
import SelfUserResources from './Modules/SelfUserResources';
import CustomDurations from './Modules/CustomDurations';
import PaymentCustomers from './Modules/PaymentCustomers';
import PaymentInstruments from './Modules/PaymentInstruments';
import CommunicationContents from './Modules/CommunicationContents';
import PaymentInstrumentSettings from './Modules/PaymentInstrumentSettings';
import BookingPayments from './Modules/BookingPayments';

vue.use(vuex);

export default new vuex.Store({
    modules: {
        Account,
        WeatherForecast,
        Authentication,
        Clients,
        PetsV2,
        Notes,
        Addresses,
        AddressSearches,
        NotificationSettings,
        AppointmentSearches,
        Histories,
        Contacts,
        ClientSearchesV2,
        BreedSearches,
        Breeds,
        Weights,
        Documents,
        Resources,
        Settings,
        PetSearchesV2,
        ProductSearches,
        ClientCount,
        ResourceCount,
        SiteCount,
        ApplicationEvents,
        Communications,
        Signatures,
        Contracts,
        ContractTemplates,
        UserSettings,
        PetProducts,
        AppointmentScheduleUpload,
        AppointmentSchedules,
        Taxes,
        ScheduleFilter,
        Appointments,
        Orders,
        Countries,
        Unavailabilities,
        OpeningHours,
        WorkingHours,
        OneOffHours,
        Sites,
        Species,
        CountryOptionSearches,
        CountryOptions,
        UserClaims,
        Employees,
        EmployeeSearches,
        UserResources,
        Events,
        EventSearches,
        EventTypes,
        AccountSettings,
        ContractTemplateHtmls,
        ContractTemplateTags,
        ContractTemplateSites,
        SmsTemplates,
        CommunicationTypes,
        SmsTemplateTags,
        EmailTemplateTags,
        Images,
        ImageSettings,
        Logos,
        LogoSettings,
        TimeZones,
        TagTypes,
        Tags,
        DateFormatSettings,
        DateFormatOptions,
        TimeFormatSettings,
        TimeFormatOptions,
        PostcodeSearches,
        Products,
        CampaignSettings,
        SmsSettings,
        EmailTemplateSettings,
        PetDurations,
        CountryFormats,
        AppointmentScheduleOrders,
        ConflictSearches,
        AppointmentAggregateSearches,
        OrderAggregateSearches,
        ClientAggregateSearches,
        BreedAggregateSearches,
        OrderItemAggregateSearches,
        ClientVisitAggregateSearches,
        ReportTypes,
        Reports,
        ReportCsvs,
        ReportSettings,
        Notifications,
        ReminderSettings,
        AppointmentCounts,
        PaymentProviders,
        PaymentOAuths,
        PaymentCheckouts,
        PaymentMethods,
        StripoTemplates,
        Campaigns,
        SmsCampaignTemplates,
        CampaignTags,
        PaymentClientCheckouts,
        PaymentTokens,
        AppointmentDeposits,
        AppointmentGroupSearches,
        AppointmentGroups,
        PaymentStatuses,
        PaymentCheckoutSearches,
        PaymentApiUser,
        PaymentApiUserSearches,
        PaymentUsers,
        EmailCampaignTemplates,
        EmailMarketingStripoDefaults,
        EmailMarketingTemplates,
        EmailMarketingDefaultSubjects,
        Subscription,
        StripoDefaultTemplateSettings,
        StripoDefaultTemplateBundleSettings,
        PurchasedCampaignTemplates,
        StripoDefaultTemplates,
        SubscriptionUpgradeTypes,
        SubscriptionUpgradeFeatures,
        SubscriptionSettings,
        SubscriptionUpgrade,
        SubscriptionUpgradeSettings,
        SubscriptionEntityPayments,
        ClientOnboardingInvites,
        SubscriptionPaymentFailures,
        SubscriptionPayments,
        CreditPacks,
        CreditPackSettings,
        PetProductsV2,
        BreedProducts,
        BreedProductSearches,
        BreedProductGroups,
        ServiceProductSearches,
        ServiceProductGroups,
        ClientMessages,
        ClientLogs,
        SubscriptionPaymentSearches,
        CreditPackSearches,
        PaymentReceipts,
        Referrers,
        ReferralRewardSettings,
        BreedCount,
        Migrations,
        BookingCount,
        Bookings,
        BookingSearches,
        BookingSettings,
        BookingTerms,
        BookingHistories,
        BookingStatuses,
        ClientUsers,
        BookingSuggestions,
        BookingSuggestionCommunications,
        AccountMigrationAllowed,
        CommunicationSearches,
        ServiceProductCount,
        EmployeeCount,
        SmsUnitCost,
        SmsCalculatorSettings,
        SubscriptionTypes,
        SmsDefaults,
        SelfUserResources,
        CustomDurations,
        PaymentCustomers,
        PaymentInstruments,
        CommunicationContents,
        PaymentInstrumentSettings,
        BookingPayments
    }
})
