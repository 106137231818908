import { CalendarDisplayMixin } from "./CalendarDisplayMixin";

export const CalendarRequiredUserSettingsMixin = {
    mixins: [CalendarDisplayMixin],
    data() {
        return {
            defaultCalendarAppointmentUserSettingValue: {
                displayType: 1,
                images: true,
                time: true,
                orders: true,
                resourceColor: true
            },
            defaultCalendarResourceUserSettingValue: {
                images: false
            }
        }
    },
    computed: {
        calendarAppointmentUserSetting() {
            return this.userSettings.find(s => s.key === 'CalendarAppointment');
        },
        calendarAppointmentUserSettingValue() {
            return Object.assign({}, this.defaultCalendarAppointmentUserSettingValue, (!!this.calendarAppointmentUserSetting ? JSON.parse(this.calendarAppointmentUserSetting.value) : null));
        },
        calendarResourceUserSetting() {
            return this.userSettings.find(s => s.key === 'CalendarResource');
        },
        calendarResourceUserSettingValue() {
            return Object.assign({}, this.defaultCalendarResourceUserSettingValue, (!!this.calendarResourceUserSetting ? JSON.parse(this.calendarResourceUserSetting.value) : null));
        },
        displayResourceColor() {
            return !!this.fetched ? !!this.calendarAppointmentUserSettingValue.resourceColor : true;
        },
        requiredPetImages() {
            return !!this.fetched ? !!this.calendarAppointmentUserSettingValue.images : false;
        },
        requiredClientAddresses() {
            return !!this.fetched ? this.calendarAppointmentUserSettingValue.displayType === 4 : false;
        },
        requiredAppointmentOrders() {
            return !!this.fetched ? !!this.calendarAppointmentUserSettingValue.orders : false;
        },
        requiredAppointmentNotes() {
            return !!this.fetched ? this.calendarAppointmentUserSettingValue.displayType === 5 : false;
        },
        requiredResourceImages() {
            return !!this.fetched ? !!this.calendarResourceUserSettingValue.images : false;
        }
    }
}
