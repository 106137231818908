<template>
    <button @click="instrumentSelected" type="button" class="btn btn-sm btn-outline-secondary my-1 py-2 w-100 d-flex justify-content-between">
        {{cardNumber}}
        <i v-if="iconClass" :class="iconClass"></i>
    </button>
</template>

<script>
    export default {
        name: 'CheckoutListItemPaymentInstrument',
        props: {
            instrument: Object
        },
        computed: {
            cardType() {
                return !!this.instrument ? this.instrument.cardType : null;
            },
            cardTypeId() {
                return !!this.cardType ? this.cardType.id : 0;
            },
            last4Digits() {
                return !!this.instrument ? this.instrument.last4Digits : '';
            },
            cardNumber() {
                return !!this.last4Digits ? "**** **** **** " + this.last4Digits : '';
            },
            iconClass() {
                const cardTypeIconMap = {
                    1: 'fa-brands fa-cc-visa',
                    2: 'fa-brands fa-cc-amex',
                    4: 'fa-brands fa-cc-diners-club',
                    5: 'fa-brands fa-cc-discover',
                    9: 'fa-brands fa-cc-jcb',
                    11: 'fa-brands fa-cc-mastercard',
                    12: 'fa-brands fa-cc-visa',
                };
                return this.cardType ? cardTypeIconMap[this.cardTypeId] || 'fa-solid fa-credit-card' : null;
            }
        },
        methods: {
            instrumentSelected() {
                this.$emit('selected', this.instrument)
            }
        }
    }
</script>
