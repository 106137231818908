<template>
    <li v-if="allowed" :class="{ active: isActive }">
        <a @click="navAndClose" class="pointer d-md-none d-block">
            <span v-if="!displayUpgradeBadge" class="nav-link-text d-flex align-items-center pl-3 ml-0 w-100">{{$t(menuName)}}</span>
            <span v-else class="nav-link-text d-flex  align-items-center pl-3 ml-0 w-100">
                {{$t(menuName)}}<span class="upgrade-badge ml-2 ml-md-auto">{{$t('Upgrade')}}</span>
            </span>
        </a>
        <a @click="navigate" class="pointer d-md-block d-none">
            <span v-if="!displayUpgradeBadge" class="nav-link-text d-flex align-items-center pl-3 ml-0 w-100">{{$t(menuName)}}</span>
            <span v-else class="nav-link-text d-flex  align-items-center pl-2 w-100">
                {{$t(menuName)}}
                <span class="upgrade-badge ml-2 ml-md-auto">{{$t('Upgrade')}}</span>
            </span>
        </a>
    </li>
</template>

<script>
    import { mapMutations } from 'vuex';
    import { NavLinkMixin } from './NavLinkMixin';

    export default {
        name: 'NavPanelSubLink',
        mixins: [NavLinkMixin],
        props: {
            menuName: String
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'toggleShowMobileNav'
            ]),
            navAndClose() {
                this.navigate();
                this.toggleShowMobileNav();
            }
        }
    }
</script>
