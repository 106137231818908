import { SubscriptionFeatureMixin } from '../../mixins/Subscriptions/SubscriptionFeatureMixin';
import router from '../../routing';
import { UserCacheMixin } from '../Shared/UserCacheMixin';

export const NavLinkMixin = {
    mixins: [UserCacheMixin, SubscriptionFeatureMixin],
    props: {
        routerName: String,
    },
    data() {
        return {
            siteAdminsAllowed: [
                'dashboard',
                'users',
                'resources',
                'appSettings',
                'campaigns',
                'campaignEmailTemplates',
                'campaignSmsTemplates',
                'bookings',
                'sentNotifications'
            ],
            employeesAllowed: [
                'schedule',
                'clients',
                'breeds',
                'products',
                'contracts',
                'emailMarketingTemplates',
                'reports',
                'myAccount',
                'paymentReports'
            ]
        }
    },
    computed: {
        isActive() {
            return this.$route.name === this.routerName;
        },
        allowed() {
            return this.isOwner
                || (this.isSiteAdmin && this.siteAdminsAllowed.indexOf(this.routerName) > -1)
                || this.employeesAllowed.indexOf(this.routerName) > -1;
        }
    },
    methods: {
        navigate() {
            if (this.subscriptionFeatureAllowed) {
                if (!this.isActive && this.allowed) {
                    router.push({ name: this.routerName });
                }
            } else if (this.$route.name !== 'upsell') {
                router.push({ name: 'upsell' });
            }
        },
        tryGetUser() {
            // do nothing
        }
    }
}
