<template>
    <tbody>
        <tr :class="classType">
            <td>{{provider}}</td>
            <td>{{date}}</td>
            <td style="text-align: right">{{amount}}</td>
            <td style="text-align: center">{{status}}</td>
            <template v-if="!displayInstruments">
                <td>
                    <button v-if="canProcess" @click="selectPreferredCard" title="Process payment" type="button" class="btn btn-sm btn-outline-secondary">
                        <i class="fal fa-arrow-right"></i>
                    </button>
                    <button v-else-if="canRefund" @click="refunding=!refunding" title="Refund payment" type="button" class="btn btn-sm btn-outline-secondary">
                        <i class="fal fa-rotate-left"></i>
                    </button>
                </td>
                <td>
                    <button v-if="canProcess" @click="cancelling=!cancelling" title="Cancel payment" type="button" class="btn btn-sm btn-outline-secondary">
                        <i class="fal fa-times"></i>
                    </button>
                    <button v-if="hasRefunds" @click="history=!history" title="Refund history" type="button" class="btn btn-sm btn-outline-secondary">
                        <i class="fal fa-chevron-down"></i>
                    </button>
                </td>
            </template>
            <template v-else>
                <td></td>
                <td></td>
            </template>
        </tr>
        <tr v-if="displayInstruments" :class="classType">
            <td colspan="6">
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <label class="text-center my-1">{{ $t('Select your preferred card') }}</label>
                    <payment-instrument-process v-if="instrument" :payment="payment" :instrument="instrument"></payment-instrument-process>
                    <div v-else class="col-md-4">
                        <button @click="selectNewCard" type="button" class="btn btn-sm btn-outline-secondary my-1 py-2 w-100 d-flex justify-content-between">
                            {{ $t('Pay with another card') }}
                            <i class="fa-solid fa-credit-card"></i>
                        </button>
                        <payment-instrument v-for="instrument in activeInstruments"
                                            :instrument="instrument"
                                            :key="instrument.id"
                                            @selected="selectInstrument">
                        </payment-instrument>
                        <div class="w-100 d-flex justify-content-center">
                            <button @click="displayInstruments = false" type="button" class="btn btn-secondary">
                                <i class="fa fa-arrow-left"></i>
                                {{$t('Back')}}
                            </button>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr v-if="history" v-for="refund in refunds" :key="refund.id" class="refund-item">
            <td>{{refund.isPartial ? 'Partial ' : 'Full '}} refund</td>
            <td>{{refund.createdUtc | moment(dateFormat)}}</td>
            <td style="text-align: right">-{{refund.amount.toFixed(2)}}</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr v-if="refunding" class="refund-item">
            <td colspan="6">
                <slotted-toggle v-model="isPartialRefund" title="Partial refund?"></slotted-toggle>
                <money-input v-if="isPartialRefund" v-model="refundAmount" title="Amount"></money-input>
                <validation-message v-if="isPartialRefund && refundValidator.amount" :message="refundValidator.amount"></validation-message>
                <div class="form-group">
                    <button @click="tryRefundCheckout" v-bind:disabled="loadingCheckouts" type="button" class="btn btn-danger" style="margin-right: 10px;">
                        Refund
                        <span v-if="loadingCheckouts" class="inline-loader"></span>
                    </button>
                    <button @click="refunding=false" type="button" class="btn btn-outline-secondary">
                        {{$t('Cancel')}}
                    </button>
                </div>
            </td>
        </tr>
        <tr v-if="cancelling">
            <td colspan="6">
                <div role="alert" class="alert alert-warning alert-icon">
                    <div class="alert-content">
                        <p> Are you sure you want to cancel this payment?</p>
                    </div>
                    <button @click="tryCancelCheckout" v-bind:disabled="loadingCheckouts" type="button" class="btn btn-danger" style="margin-right: 10px;">
                        Yes
                        <span v-if="loadingCheckouts" class="inline-loader"></span>
                    </button>
                    <button @click="cancelling=false" type="button" class="close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
    import { CheckoutListItemMixin } from './CheckoutListItemMixin';
    import { PaymentCustomersCacheMixin } from './PaymentCustomersCacheMixin';
    import { PaymentInstrumentsCacheMixin } from './PaymentInstrumentsCacheMixin';
    import ValidationMessage from '../ErrorValidation/ValidationMessage.vue';
    import MoneyInput from '../Shared/Input/MoneyInput.vue';
    import SlottedToggle from '../Shared/Input/SlottedToggle.vue';
    import CheckoutListItemPaymentInstrument from './CheckoutListItemPaymentInstrument.vue';

    export default {
        name: 'CheckoutListItemDesktop',
        mixins: [CheckoutListItemMixin, PaymentCustomersCacheMixin, PaymentInstrumentsCacheMixin],
        props: {
            id: String,
            number: Number
        },
        data() {
            return {
                cancelling: false,
                refunding: false,
                history: false,
                instrument: null,
                displayInstruments: false
            }
        },
        computed: {
            clientId() {
                return this.id
            },
            classType() {
                return this.number % 2 > 0 ? 'odd' : 'even';
            },
            providerTypeId() {
                return !!this.payment && !!this.payment.providerType ? this.payment.providerType.id : 0;
            },
            customer() {
                return this.customers.length > 0 ? this.customers.find(c => c.providerType.id == this.providerTypeId) : null
            },
            customerId() {
                return !!this.customer ? this.customer.id : ''
            },
            activeInstruments() {
                return this.instruments.length > 0 ? this.instruments.filter(i => i.statusType.id === 1) : []
            },
            noActiveInstruments() {
                return this.activeInstruments.length < 1 && !this.loadingInstruments
            }
        },
        methods: {
            afterCancelCheckout() {
                this.cancelling = false;
            },
            afterRefundCheckout() {
                this.refunding = false;
            },
            selectPreferredCard() {
                if (this.noActiveInstruments) {
                    this.selectNewCard();
                } else {
                    this.displayInstruments = true;
                }
            },
            selectNewCard() {
                this.displayInstruments = false;
                this.instrument = null;
                this.signalProcess();
            },
            selectInstrument(value) {
                this.displayInstruments = false;
                this.instrument = value;
                this.signalProcess();
                this.instrument = null;
            },
            refreshCustomers() {
                //do nothing
            },
            signalProcess() {
                if (this.canProcess) {
                    this.$emit('process', this.payment, this.instrument, this.customer);
                }
            }
        },
        components: {
            'slotted-toggle': SlottedToggle,
            'money-input': MoneyInput,
            'validation-message': ValidationMessage,
            'payment-instrument': CheckoutListItemPaymentInstrument
        }
    }
</script>
