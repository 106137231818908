import { SettingUploadMixin } from "../../../components/Shared/SettingUploadMixin";
import { SiteBookingSettingMixin } from "./SiteBookingSettingMixin";
import { BookingSettingsCacheMixin } from "../../../mixins/Bookings/BookingSettingsCacheMixin";

export const SiteBookingSettingUploadMixin = {
    mixins: [SettingUploadMixin, SiteBookingSettingMixin, BookingSettingsCacheMixin],
    data() {
        return {
            bookingEnabled: false,
            clientType: 1,
            reviewType: 1,
            serviceSelectEnabled: false,
            pricingType: 1,
            multipleServiceEnabled: true,
            minNotice: 1,
            minNoticeUnit: 'days',
            maxNotice: 30,
            maxNoticeUnit: 'days',
            singlePetResourceEnabled: true,
            maxPets: 6,
            maxRegisteredPets: 10,
            maxServices: 4,
            displayPetProfileImage: false,
            blockOffEvents: true,
            providerTypeId: 0,
            unregisteredClientPaymentModeId: 0,
            registeredClientPaymentModeId: 0,
            isDepositPercentage: false,
            depositFixedAmount: 0,
            depositPercentage: 0
        }
    },
    computed: {
        settingEditModel() {
            return {
                bookingEnabled: this.bookingEnabled,
                clientType: this.clientType,
                reviewType: this.reviewType,
                serviceSelectEnabled: this.serviceSelectEnabled,
                pricingType: this.pricingType,
                multipleServiceEnabled: this.multipleServiceEnabled,
                minNotice: this.minNotice,
                minNoticeUnit: this.minNoticeUnit,
                maxNotice: this.maxNotice,
                maxNoticeUnit: this.maxNoticeUnit,
                singlePetResourceEnabled: this.singlePetResourceEnabled,
                maxPets: this.maxPets,
                maxRegisteredPets: this.maxRegisteredPets,
                maxServices: this.maxServices,
                displayPetProfileImage: this.displayPetProfileImage,
                blockOffEvents: this.blockOffEvents,
                providerTypeId: this.providerTypeId,
                unregisteredClientPaymentModeId: this.unregisteredClientPaymentModeId,
                registeredClientPaymentModeId: this.registeredClientPaymentModeId,
                isDepositPercentage: this.isDepositPercentage,
                depositFixedAmount: this.depositFixedAmount,
                depositPercentage: this.depositPercentage
            }
        },
        selectedMinNoticeUnit() {
            return this.noticeUnitOptions.find(nuo => nuo.value === this.minNoticeUnit);
        },
        selectedMaxNoticeUnit() {
            return this.noticeUnitOptions.find(nuo => nuo.value === this.maxNoticeUnit);
        },
        calculatedMinNotice() {
            return (!!this.selectedMinNoticeUnit ? this.selectedMinNoticeUnit.multiplier : 0) * this.minNotice;
        },
        calculatedMaxNotice() {
            return (!!this.selectedMaxNoticeUnit ? this.selectedMaxNoticeUnit.multiplier : 0) * this.maxNotice;
        },
        isPaymentModeDepositRequired() {
            return this.unregisteredClientPaymentModeId === 2 || this.registeredClientPaymentModeId === 2;
        },
        depositFixedAmountValid() {
            return !this.isPaymentModeDepositRequired || this.isDepositPercentage || (!isNaN(this.depositFixedAmount) && !(this.depositFixedAmount < 0));
        },
        depositPercentageValid() {
            return !this.isPaymentModeDepositRequired || !this.isDepositPercentage || (!isNaN(this.depositPercentage) && !(this.depositPercentage < 0));
        },
        settingEditModelValid() {
            return this.calculatedMaxNotice > this.calculatedMinNotice &&
                this.maxPets > 0 && this.maxPets <= this.petsLimit &&
                this.maxRegisteredPets > 0 && this.maxRegisteredPets <= this.petsLimit &&
                this.maxServices > 0 && this.maxServices <= this.servicesLimit &&
                this.depositFixedAmountValid && this.depositPercentageValid;
        }
    },
    methods: {
        setClientType(value) {
            this.clientType = value;
        },
        setReviewType(value) {
            this.reviewType = value;
        },
        setPricingType(value) {
            this.pricingType = value;
        },
        setMinNoticeUnit(value) {
            this.minNoticeUnit = value
        },
        setMaxNoticeUnit(value) {
            this.maxNoticeUnit = value;
        },
        refreshSettingEditModel() {
            this.bookingEnabled = this.settingObject.bookingEnabled;
            this.clientType = this.settingObject.clientType;
            this.reviewType = this.settingObject.reviewType;
            this.serviceSelectEnabled = this.settingObject.serviceSelectEnabled;
            this.pricingType = this.settingObject.pricingType;
            this.multipleServiceEnabled = this.settingObject.multipleServiceEnabled;
            this.minNotice = this.settingObject.minNotice;
            this.minNoticeUnit = this.settingObject.minNoticeUnit;
            this.maxNotice = this.settingObject.maxNotice;
            this.maxNoticeUnit = this.settingObject.maxNoticeUnit;
            this.singlePetResourceEnabled = this.settingObject.singlePetResourceEnabled;
            this.maxPets = this.settingObject.maxPets;
            this.maxRegisteredPets = this.settingObject.maxRegisteredPets;
            this.maxServices = this.settingObject.maxServices;
            this.displayPetProfileImage = this.settingObject.displayPetProfileImage;
            this.blockOffEvents = this.settingObject.blockOffEvents;
            this.providerTypeId = this.settingObject.providerTypeId;
            this.unregisteredClientPaymentModeId = this.settingObject.unregisteredClientPaymentModeId;
            this.registeredClientPaymentModeId = this.settingObject.registeredClientPaymentModeId;
            this.isDepositPercentage = this.settingObject.isDepositPercentage;
            this.depositFixedAmount = this.settingObject.depositFixedAmount;
            this.depositPercentage = this.settingObject.depositPercentage;
        }
    }
}
