import { SiteIdMixin } from "../../../components/Shared/Site/SiteIdMixin";
import { SubscriptionFeatureMixin } from "../../Subscriptions/SubscriptionFeatureMixin";

export const SiteBookingSettingMixin = {
    mixins: [SiteIdMixin, SubscriptionFeatureMixin],
    data() {
        return {
            noticeUnitOptions: [
                { name: 'Days', value: 'days', multiplier: 1 },
                { name: 'Weeks', value: 'weeks', multiplier: 7 },
                { name: 'Months', value: 'months', multiplier: 30 }
            ],
            clientTypeOptions: [
                { name: 'Registered Clients Only', value: 1 },
                { name: 'Registered & Unregistered Clients', value: 2 }
            ],
            pricingTypeOptions: [
                { name: 'Published Services Only', value: 1 },
                { name: 'Pet Profile Price Only', value: 5 },
                { name: 'Pet Profile Price + Published Services', value: 2 },
                { name: 'Breed Price + Published Services', value: 3 },
                { name: 'Auto', value: 4 }
            ]
        }
    },
    computed: {
        computedFeatureKey() {
            return 'onlineBooking';
        },
        unregisteredClientBookingFeatureFound() {
            return !!this.subscriptionFeatures && !!this.subscriptionFeatures.hasOwnProperty('unregisteredClientBookingAllowed');
        },
        unregisteredClientBookingAllowed() {
            return this.unregisteredClientBookingFeatureFound ? this.subscriptionFeatures['unregisteredClientBookingAllowed'] : false;
        },
        reviewTypeOptions() {
            if (!!this.unregisteredClientBookingAllowed) {
                return [
                    { name: 'None (Review All)', value: 0 },
                    { name: 'Registered Clients Only', value: 1 },
                    { name: 'Registered & Unregistered Clients', value: 2 }
                ]
            } else {
                return [
                    { name: 'No (Review Required)', value: 0 },
                    { name: 'Yes', value: 1 }
                ]
            }
        },
        ownerId() {
            return this.siteId;
        },
        ownerTypeId() {
            return 12;
        },
        settingKey() {
            return 'Booking';
        },
        defaultSettingObject() {
            return {
                bookingEnabled: false,
                clientType: 2,
                reviewType: 0,
                serviceSelectEnabled: false,
                pricingType: 1,
                multipleServiceEnabled: false,
                minNotice: 1,
                minNoticeUnit: 'days',
                maxNotice: 30,
                maxNoticeUnit: 'days',
                singlePetResourceEnabled: true,
                maxPets: 6,
                maxRegisteredPets: 10,
                maxServices: 4,
                blockOffEvents: true,
                providerTypeId: 0,
                unregisteredClientPaymentModeId: 0,
                registeredClientPaymentModeId: 0,
                isDepositPercentage: false,
                depositFixedAmount: 0,
                depositPercentage: 0
            }
        }
    }
}
