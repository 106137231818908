<template>
    <div class="content">
        <table-mobile>
            <ul class="nav nav-tabs-ps justify-content-center" role="tablist">
                <li class="nav-item w-50">
                    <a class="nav-link text-center active" data-toggle="tab" href="#opening-hours-pane" role="tab">{{$t('Opening Hours')}}</a>
                </li>
                <li class="nav-item w-50">
                    <a class="nav-link text-center" data-toggle="tab" href="#working-hours-pane" role="tab">{{$t('Working Hours')}}</a>
                </li>
            </ul>
            <div class="tab-content pt-3">
                <div class="tab-pane fade show active" id="opening-hours-pane">
                    <header-mobile v-for="dayId in dayIds" :day-id="dayId" :opening-hours="openingHours" :key="dayId" @header-clicked="onHeaderClicked"></header-mobile>
                </div>
                <div class="tab-pane fade" id="working-hours-pane">
                    <resource-mobile v-for="resource in resources" :key="resource.id" :resource="resource" :working-hours="workingHours" :one-off-hours="oneOffHours" @row-clicked="onRowClicked" @cell-clicked="onCellClicked"></resource-mobile>
                </div>
            </div>
        </table-mobile>
        <table-desktop>
            <template #header>
                <tr role="row">
                    <sort-header v-model="sortModel" :key-name="''" class="text-center col-2">{{$t('Resource')}}</sort-header>
                    <header-desktop v-for="dayId in dayIds" :day-id="dayId" :opening-hours="openingHours" :key="dayId" @header-clicked="onHeaderClicked"></header-desktop>
                </tr>
            </template>
            <template #rows>
                <resource-desktop v-for="(resource, index) in sortedResources"
                                :key="resource.id"
                                :resource="resource"
                                :number="index"
                                :working-hours="workingHours"
                                :one-off-hours="oneOffHours"
                                @row-clicked="onRowClicked"
                                @cell-clicked="onCellClicked"></resource-desktop>
            </template>
        </table-desktop>
        <upload-opening-hours :day-id="dayId" ref="openingHoursUpload"></upload-opening-hours>
        <upload-hours :resource="resource" :day-id="dayId" :date="date" :one-off="oneOff" :opening-hour="openingHour" ref="resourceHoursUpload"></upload-hours>
    </div>
</template>

<script>
    import ResourceHoursDesktop from "./ResourceHoursDesktop.vue";
    import { ResourceCacheMixin } from "../../Shared/Resources/ResourceCacheMixin";
    import UploadHoursDialog from "./UploadHoursDialog.vue";
    import { WorkingHoursCacheMixin } from "../../Shared/WorkingHours/WorkingHoursCacheMixin";
    import { OneOffHoursSearchMixin } from "../../Shared/OneOffHours/OneOffHoursSearchMixin";
    import TableDesktop from "../../Shared/Layout/TableDesktop.vue";
    import TableMobile from "../../Shared/Layout/TableMobile.vue";
    import { OpeningHoursCacheMixin } from "../../Shared/OpeningHours/OpeningHoursCacheMixin";
    import { ImageCachesMixin } from "../../Shared/Images/ImageCachesMixin";
    import OpeningHoursDesktop from "./OpeningHoursDesktop.vue";
    import UploadOpeningHours from "./UploadOpeningHours.vue";
    import OpeningHoursMobile from "./OpeningHoursMobile.vue";
    import ResourceHoursMobile from "./ResourceHoursMobile.vue";
    import TableSortableHeader from "../../Shared/Layout/TableSortableHeader.vue";

    export default {
        name: 'ResourceHours',
        mixins: [WorkingHoursCacheMixin, OneOffHoursSearchMixin, ResourceCacheMixin, OpeningHoursCacheMixin, ImageCachesMixin],
        props: {
            date: Date,
        },
        data() {
            return {
                position: 0,
                dayId: 0,
                resource: null,
                oneOff: false
            }
        },
        computed: {
            now() {
                return this.date;
            },
            sortedResources() {
                return this.ascending ?
                    this.resources.sort((a, b) => a.name > b.name ? 1 : -1) :
                    this.resources.sort((a, b) => a.name < b.name ? 1 : -1);
            },
            openingHour() {
                return this.openingHours.find(oh => oh.dayOfWeek.id === this.dayId);
            },
            ownerIds() {
                return this.resources.length > 0 ? this.resources.map(r => r.id) : [];
            },
            ownerTypeId() {
                return 14;
            }
        },
        methods: {
            onHeaderClicked(dayId) {
                this.dayId = dayId;
                const element = this.$refs.openingHoursUpload.$el;
                $(element).modal('show');
            },
            onCellClicked(info) {
                this.resource = info.resource;
                this.dayId = info.dayId;
                this.oneOff = info.oneOff;
                this.date = this.getWeekStart().add(this.dayIds.indexOf(info.dayId), 'days').toDate();
                const element = this.$refs.resourceHoursUpload.$el;
                $(element).modal('show');
            },
            onRowClicked(resource) {
                this.$emit('resource-clicked', resource);
            }
        },
        components: {
            'resource-desktop': ResourceHoursDesktop,
            'header-desktop': OpeningHoursDesktop,
            'upload-hours': UploadHoursDialog,
            'upload-opening-hours': UploadOpeningHours,
            'table-desktop': TableDesktop,
            'table-mobile': TableMobile,
            'header-mobile': OpeningHoursMobile,
            'resource-mobile': ResourceHoursMobile,
            'sort-header': TableSortableHeader
        }
    }
</script>
