<template>
    <div class="form-group">
        <div id="sumup-card"></div>
    </div>
</template>

<script>
    export default {
        name: 'SumUpPaymentWidget',
        props: {
            checkout: Object
        },
        computed: {
            providerReference() {
                return !!this.checkout ? this.checkout.providerReference : '';
            }
        },
        mounted() {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js';
            script.onload = () => {
                SumUpCard.mount({
                    id: 'sumup-card',
                    checkoutId: this.providerReference,
                    onResponse: (type, body) => {
                        if (type === 'success') {
                            this.$emit('completed');
                        } else if (type === 'error') {
                            this.$emit('errored', !!body.detail ? body.detail : 'An unknown error occurred.');
                        }
                    }
                });
            }
            document.head.appendChild(script);
        }
    }
</script>
