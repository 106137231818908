<template>
    <tr>
        <td>
            <section class="mb-1 resource-hour-mobile">
                <div :ref="ariaRef" aria-expanded="true" :data-target="dataTarget" data-toggle="collapse" class="accordion-header pl-2 py-2 w-100">
                    <figure class="pet-photo p-0 mr-0">
                        <img v-if="heroImage" :alt="heroImage.uploadedName" :src="heroImage.thumbnails.small" width="100">
                        <template v-else>
                            <img alt="Default" src="../../../assets/images/Dog_Pink_BG_128.png" width="100">
                        </template>
                    </figure>
                    <div class="resource-name-wrap-text">{{resourceName}}</div>
                    <i class="far fa-chevron-down ml-auto"></i>
                </div>
                <div class="collapse" :id="id">
                    <div class="card card-body">
                        <div class="d-flex flex-column">
                            <day-cell v-for="dayId in dayIds" :key="dayId" :day-id="dayId" :working-hours="resourceWorkingHours" :oneOffHours="resourceOneOffHours" @cell-clicked="onCellClicked"></day-cell>
                            <button @click="onRowClicked" class="btn btn-sm btn-outline align-self-end px-2 mt-2">
                                <i class="fal fa-edit mr-1"></i>
                                {{$t('EDIT')}}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </td>
    </tr>
</template>

<script>
    import ResourceHoursDayMobile from "./ResourceHoursDayMobile.vue";
    import { ResourceHoursMixin } from "./ResourceHoursMixin";
    import { ImageCacheMixin } from "../../Shared/Images/ImageCacheMixin";

    export default {
        name: 'ResourceHoursMobile',
        mixins: [ResourceHoursMixin, ImageCacheMixin],
        computed: {
            id() {
                return 'collapse-resource-' + this.resourceId;
            },
            ariaRef() {
                return this.id + '-ref';
            },
            dataTarget() {
                return '#' + this.id;
            },
            ownerId() {
                return this.resourceId;
            },
            ownerTypeId() {
                return 14;
            },
            heroImage() {
                return this.images.length > 0 ? this.images[0] : null;
            }
        },
        methods: {
            trySearchImages() {
                // do nothing
            }
        },
        components: {
            'day-cell': ResourceHoursDayMobile
        }
    }
</script>

<style scoped>
    .resource-name-wrap-text {
        white-space: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
    }
</style>
