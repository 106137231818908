<template>
    <div>
        <error-banner v-if="error" :message="error"></error-banner>
        <sum-up v-if="providerTypeId == 1" :customer="customer"></sum-up>
        <span v-if="loadingInstruments && !displayTable" class="inline-loader"></span>
        <table v-if="displayTable" class="table table-striped ps-table table-hover">
            <thead>
                <tr>
                    <th>{{$t('Type')}}</th>
                    <th class="text-center">{{$t('Last 4 Digits')}}</th>
                    <th class="text-center">{{$t('Status')}}</th>
                    <th>{{$t('Action')}}</th>
                </tr>
            </thead>
            <payment-instruments-item v-for="instrument in instruments"
                                      :key="instrument.id"
                                      :id="clientId"
                                      :instrument="instrument">
            </payment-instruments-item>
        </table>
    </div>
</template>

<script>
    import { PaymentInstrumentsUpdateMixin } from "../../Payments/PaymentInstrumentsUpdateMixin";
    import PaymentInstrumentsProviderSumUp from "./PaymentInstrumentsProviderSumUp.vue"
    import PaymentInstrumentsItem from "./PaymentInstrumentsItem.vue";
    import ErrorBanner from "../../ErrorValidation/ErrorBanner.vue";

    export default {
        name: 'PaymentInstrumentsProvider',
        mixins: [PaymentInstrumentsUpdateMixin],
        props: {
            customer: Object
        },
        computed: {
            customerId() {
                return !!this.customer ? this.customer.id : ''
            },
            clientId() {
                return !!this.customer ? this.customer.clientId : ''
            },
            providerTypeId() {
                return !!this.customer ? this.customer.providerType.id : 0;
            },
            displayTable() {
                return this.instruments.length > 0;
            }
        },
        components: {
            'error-banner': ErrorBanner,
            'payment-instruments-item': PaymentInstrumentsItem,
            'sum-up': PaymentInstrumentsProviderSumUp
        }
    }
</script>
