<template>
    <div class="mobile-filters" v-bind:class="{ 'show': value }">
        <div class="d-flex flex-column">
            <button @click="close" class="text-right close" type="button">
                <i class="fal fa-times"></i>
            </button>
            <div v-if="value" class="mobile-filters--inner">
                <view-settings v-model="loadingViewSettings"></view-settings>
                <schedule-settings v-model="loadingScheduleSettings"></schedule-settings>
                <location-select></location-select>
                <resource-select></resource-select>
                <a @click="clearListFilters" class="clear-filters pointer">Clear filters</a>
            </div>
        </div>
        <div class="fixed-action-btns action-btns mobile-filters-action-btns">
            <button @click="save" v-bind:disabled="loading" class="btn btn-primary" type="button">
                {{$t('Save')}}
                <span v-if="loading" class="inline-loader"></span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex';
    import ScheduleMobileFiltersLocations from "./ScheduleMobileFiltersLocations.vue";
    import ScheduleMobileFiltersResources from "./ScheduleMobileFiltersResources.vue";
    import CalendarViewSettingsMobile from './Settings/CalendarViewSettingsMobile.vue';
    import ScheduleSettingsMobile from './Settings/ScheduleSettingsMobile.vue';

    export default {
        name: 'ScheduleMobileFilters',
        data() {
            return {
                loadingScheduleSettings: false,
                loadingViewSettings: false
            }
        },
        props: {
            value: Boolean
        },
        computed: {
            loading() {
                return this.loadingScheduleSettings || this.loadingViewSettings;
            }
        },
        methods: {
            ...mapMutations('ApplicationEvents', [
                'triggerSaveScheduleSettings'
            ]),
            ...mapMutations('ScheduleFilter', [
                'clearListFilters'
            ]),
            close() {
                this.$emit('input', false);
            },
            save() {
                this.triggerSaveScheduleSettings();
            }
        },
        components: {
            'schedule-settings': ScheduleSettingsMobile,
            'location-select': ScheduleMobileFiltersLocations,
            'resource-select': ScheduleMobileFiltersResources,
            'view-settings': CalendarViewSettingsMobile
        }
    }
</script>
