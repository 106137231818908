<template>
    <div class="w-100">
        <label class="px-2 mt-1" v-if="displaySiteName">{{siteName}}</label>
        <div v-for="option in options" :key="option.id" class="dropdown-item px-2">
            <label class="custom-control custom-checkbox">
                <input v-model="option.selected" @change="onChange" class="custom-control-input" type="checkbox">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">{{option.name}}</span>
            </label>
        </div>
    </div>
</template>

<script>
    import { ResourceCacheMixin } from "../Shared/Resources/ResourceCacheMixin";

    export default {
        name: 'ScheduleFilterResourceItem',
        mixins: [ResourceCacheMixin],
        props: {
            value: Array,
            site: Object,
            displaySiteName: false
        },
        computed: {
            siteId() {
                return this.site.id;
            },
            siteName() {
                return this.site.name;
            },
            options() {
                return this.resources.map(r => Object.assign({}, r, {
                    selected: !!this.value.find(v => v.id === r.id)
                }));
            },
            extantOptions() {
                return this.value.filter(v =>
                    !this.resources.find(r => r.id === v.id) ||
                    !!this.options.find(o => !!o.selected && o.id === v.id));
            },
            addedOptions() {
                return this.options.filter(o => o.selected && !this.value.find(v => v.id === o.id));
            }
        },
        methods: {
            onChange() {
                this.$emit('input', this.extantOptions.concat(this.addedOptions));
            }
        }
    }
</script>
