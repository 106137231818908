<template>
    <datepicker v-model="date"
                :format="customFormatter" 
                :input-class="localInputClass"
                :calendar-button="showCalendarButton"
                :highlighted="highlightToday"
                :language="currentLocale"
                calendar-button-icon="far fa-calendar-alt">
    </datepicker>
</template>

<script>
    import moment from 'moment-timezone';
    import Datepicker from 'vuejs-datepicker';
    import { en, es, fr, de, sv } from 'vuejs-datepicker/dist/locale';
    import { DatePickerFormatterMixin } from './DatePickerFormatterMixin';

    export default {
        name: 'MyDatePicker',
        mixins: [DatePickerFormatterMixin],
        props: {
            value: Date,
            inputClass: String,
            showCalendarButton: false
        },
        computed: {
            date: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            highlightToday() {
                const today = moment().startOf('day');
                return {
                    dates: [today.toDate()],
                    customPredictor(date) {
                        return moment(date).isSame(today, 'day');
                    }
                };
            },
            localInputClass() {
                return ['form-control caleran pointer', this.inputClass].join(' ');
            },
            currentLocale() {
                const lang = localStorage.getItem('lang') || 'en';
                switch (lang) {
                    case 'de':
                        return de;
                    case 'es':
                        return es;
                    case 'fr':
                        return fr;
                    case 'sv':
                        return sv;
                    default:
                        return en;
                }
            }
        },
        components: {
            Datepicker
        }
    }
</script>
