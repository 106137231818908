import { EmailHelper } from './EmailHelper';

export const UserHelper = {
    getUserAddValidator() {
        return {
            isValid: false,
            firstName: '',
            lastName: '',
            siteIds: '',
            email: '',
            password: '',
            check(model) {
                this.firstName = !model.firstName ? 'Please enter a first name.' : '';
                this.lastName = !model.lastName ? 'Please enter a last name.' : '';
                this.siteIds = model.siteIds.length < 1 ? 'Please select at least 1 site.' : '';
                this.email = !model.email ? 'Please enter an email' : '';
                this.email = !model.email ? 'Please enter an email' : (EmailHelper.isValid(model.email) ? '' : 'Please enter a valid email');
                this.password = !!model.hasPassword && !model.password ? 'Please enter a password' : '';
                this.isValid = !this.firstName && !this.lastName && !this.siteIds && !this.email && !this.password;
            }
        }
    },
    getUserUpdateValidator() {
        return {
            isValid: false,
            firstName: '',
            lastName: '',
            siteIds: '',
            email: '',
            check(model) {
                this.firstName = !model.firstName ? 'Please enter a first name.' : '';
                this.lastName = !model.lastName ? 'Please enter a last name.' : '';
                this.siteIds = model.siteIds.length < 1 ? 'Please select at least 1 site.' : '';
                this.email = !model.email ? 'Please enter an email' : '';
                this.isValid = !this.firstName && !this.lastName && !this.siteIds && !this.email;
            }
        }
    },
    getNotificationAddValidator() {
        return {
            isValid: false,
            text: '',
            check(model) {
                this.text = !!model.text ? '' : 'Please enter a message';
                this.isValid = !this.text;
            }
        }
    }
}
