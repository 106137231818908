import { PaymentApi } from "../../services/PaymentApi";

const state = () => ({
    paymentInstrumentsCaches: []
});

const getters = {
    findPaymentInstrumentsCache: (state) => key => state.paymentInstrumentsCaches.find(pic => pic.key === key)
}

const mutations = {
    setLoading(state, { key, loading }) {
        const existing = state.paymentInstrumentsCaches.find(pic => pic.key === key);
        if (!!existing) {
            existing.loading = !!loading;
            existing.retry = false;
        } else {
            state.paymentInstrumentsCaches.push({ key: key, values: [], loading: !!loading });
        }
    },
    setInstruments(state, { key, instruments }) {
        const existing = state.paymentInstrumentsCaches.find(pic => pic.key === key);
        if (!!existing) {
            existing.values = instruments;
            existing.loading = false;
        } else {
            state.paymentInstrumentsCaches.push({ key: key, values: instruments, loading: false });
        }
    },
    setRetry(state, key) {
        const existing = state.paymentInstrumentsCaches.find(pic => pic.key === key);
        if (!!existing) {
            existing.retry = true;
            existing.loading = false;
        }
    }
}

const actions = {
    findInstruments({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.get('Customer/' + key +'/PaymentInstrument')
                .then(response => {
                    commit('setInstruments', { key: key, instruments: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    updateInstruments({ commit }, key) {
        return new Promise((resolve, reject) => {
            commit('setLoading', { key: key, loading: true });
            PaymentApi.post('Customer/' + key + '/PaymentInstrument')
                .then(response => {
                    commit('setInstruments', { key: key, instruments: response.data });
                    resolve(response);
                })
                .catch(error => {
                    commit('setRetry', key);
                    reject(error);
                });
        });
    },
    deleteInstrument({ commit }, instrument) {
        commit('setLoading', { key: instrument.customerId, loading: true });
        return new Promise((resolve, reject) => {
            PaymentApi.delete('PaymentInstrument/' + instrument.id)
                .then(response => resolve(response))
                .catch(error => reject(error))
                .finally(() => commit('setLoading', { key: instrument.customerId, loading: false }));
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
