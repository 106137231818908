import { PaymentInstrumentsUpdateMixin } from "./PaymentInstrumentsUpdateMixin"
import { ErrorHelper } from "../../helpers/ErrorHelper";
import { mapActions } from 'vuex';

export const PaymentInstrumentDeleteMixin = {
    mixins: [PaymentInstrumentsUpdateMixin],
    props: {
        instrument: Object
    },
    methods: {
        ...mapActions('PaymentInstruments', [
            'deleteInstrument'
        ]),
        tryDeleteInstrument() {
            const self = this;
            if (!self.instrument || !!self.loadingInstruments) {
                return;
            }
            self.error = '';
            self.deleting = true;
            self.deleteInstrument(self.instrument)
                .then(() => self.afterDeletedInstrument())
                .catch(error => self.error = ErrorHelper.getError(error));
        },
        afterDeletedInstrument() {
            // override if necessary
        }
    }
}
