import vue from 'vue';
import vueClipboard from 'vue-clipboard2';
import { ObserveVisibility } from 'vue-observe-visibility';
import app from './App.vue';
import 'hammerjs';
import i18n from './i18n';
import moment from 'moment';

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

import "shepherd.js/dist/css/shepherd.css";
import "vue-popperjs/dist/vue-popper.css";
import 'bootstrap';
import './assets/css/main.min.css';
import 'es6-promise/auto';
import store from './store';
import router from './routing';

vue.use(vueClipboard);
vue.use(require('vue-moment'));
vue.directive('observe-visibility', ObserveVisibility);

const defaultLang = localStorage.getItem('lang') || 'en';
moment.locale(defaultLang);

new vue({
    data: () => ({
        rootApp: "app.entry point!"
    }),
    router,
    store,
    i18n,
    render: (h) => h(app)
}).$mount('#app');
