<template>
    <div class="row form-group">
        <div class="col-md-4">
            <label>{{$t('View type')}}</label>
        </div>
        <div class="col-md-8">
            <div class="calendar-control view-selector btn-group d-none d-md-flex mw-400">
                <button v-for="view in scheduleViews" class="btn" v-bind:class="{ active: scheduleView===view.value }" type="button" @click="scheduleView=view.value">{{$t(view.name)}}</button>
            </div>
            <div v-if="isResourceScheduleView" class="mt-2">
                <label>{{$t('Sort By:')}}</label>
                <div class="bootstrap-select form-control">
                    <button type="button" data-toggle="dropdown" class="btn dropdown-toggle btn-light">
                        {{selectedOptionName}}
                    </button>
                    <div class="inner show">
                        <ul class="dropdown-menu">
                            <li v-for="option in options"
                                class="dropdown-item pointer"
                                :key="option.id"
                                @click="setResourceOrder(option.value)"
                                v-bind:class="{'active': option.id === selectedOptionId}">
                                {{$t(option.name)}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ScheduleViewsMixin } from "../ScheduleViewsMixin";
    import { CalendarViewSettingsMixin } from "./CalendarViewSettingsMixin";

    export default {
        name: 'CalendarViewSettingsDesktop',
        mixins: [CalendarViewSettingsMixin, ScheduleViewsMixin],
        computed: {
            isResourceScheduleView() {
                return this.scheduleView === 'resourceTimeGridDay';
            }
        }
    }
</script>
