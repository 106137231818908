<template>
    <div class="col">
        <div class="row">
            <div class="col-md-8">
                <client-details v-model="detailsStatus" :id="clientId"></client-details>
                <collapsed-section id="client-appointments" :title="$t('Appointment History')">
                    <client-appointments :id="clientId"></client-appointments>
                </collapsed-section>
                <collapsed-section id="alt-contact" :title="$t('Alternative Contact')">
                    <client-alt-contact v-model="altContactStatus" :client-id="clientId"></client-alt-contact>
                </collapsed-section>
                <collapsed-section id="vet-contact" :title="$t('Vet Details')">
                    <client-vet-contact v-model="vetContactStatus" :client-id="clientId"></client-vet-contact>
                </collapsed-section>
                <collapsed-section id="client-documents" :title="$t('Documents')">
                    <client-document :id="clientId"></client-document>
                </collapsed-section>
                <collapsed-section id="client-onboarding" :title="$t('Registration Invitation')" @collapsed="toggleOnboarding++">
                    <client-onboarding :id="clientId" :toggle="toggleOnboarding"></client-onboarding>
                </collapsed-section>
                <collapsed-section v-if="isPaymentAdmin && isOwner" id="payment-customer" :title="$t('Payment Providers')">
                    <payment-customer :id="clientId"></payment-customer>
                </collapsed-section>
                <collapsed-section v-if="isPaymentAdmin && isOwner" id="card-on-file" :title="$t('Card on File')">
                    <template #tooltip>
                        <tooltip>
                            <span>{{$t('You can use these details to charge the client for no-shows.')}}</span>
                        </tooltip>
                    </template>
                    <payment-instruments :id="clientId"></payment-instruments>
                </collapsed-section>
            </div>
            <div class="col-md-4">
                <client-notes v-model="notesStatus" :client-id="clientId"></client-notes>
                <collapsed-section id="notification-settings" :title="$t('Notification Settings')">
                    <client-notification-settings v-model="notificationSettingsStatus" :id="clientId"></client-notification-settings>
                </collapsed-section>
                <client-alert v-model="alertStatus" :client-id="clientId"></client-alert>
                <collapsed-section id="client-logs" :title="$t('Event Logs')">
                    <template #tooltip>
                        <tooltip>
                            <span>{{$t('Logs are retained for up to 30 days and refreshed every hour.')}}</span>
                        </tooltip>
                    </template>
                    <client-logs :client="client"></client-logs>
                </collapsed-section>
                <collapsed-section v-if="isSiteAdmin" id="client-user" :title="$t('Portal Access')">
                    <client-user-account v-model="userAccountStatus" :id="clientId"></client-user-account>
                </collapsed-section>
            </div>
        </div>
    </div>
</template>

<script>
    import { StatusEmitterMixin } from '../../mixins/Shared/StatusEmitterMixin';
    import { UserCacheMixin } from '../Shared/UserCacheMixin';
    import { PaymentApiUserCacheMixin } from '../../mixins/Payments/PaymentApiUserCacheMixin';
    import Tooltip from '../Shared/Layout/Tooltip.vue';
    import CollapsedSection from '../Shared/Layout/CollapsedSection.vue';
    import ClientDetailsSection from './ClientDetailsSection.vue';
    import ClientNotesSection from './ClientNotesSection.vue';
    import ClientAppointmentSection from './ClientAppointmentSection.vue';
    import ClientDocumentSection from './Documents/ClientDocumentSection.vue';
    import ClientAlertSection from './ClientAlertSection.vue';
    import ClientOnboardingInviteSection from './ClientOnboardingInviteSection.vue';
    import ClientLogsSection from './Logs/ClientLogsSection.vue';
    import ClientEditAltContactSection from './ClientEdit/ClientEditAltContactSection.vue';
    import ClientEditVetContactSection from './ClientEdit/ClientEditVetContactSection.vue';
    import ClientEditNotificationSettingsSection from './ClientEdit/ClientEditNotificationSettingsSection.vue';
    import ClientEditUserAccountSection from './ClientEdit/ClientEditUserAccountSection.vue';
    import PaymentCustomers from './PaymentCustomers/PaymentCustomers.vue';
    import PaymentInstruments from './PaymentInstruments/PaymentInstruments.vue';

    export default {
        name: 'ClientEdit',
        mixins: [StatusEmitterMixin, UserCacheMixin, PaymentApiUserCacheMixin],
        props: {
            client: Object
        },
        data() {
            return {
                detailsStatus: {},
                altContactStatus: {},
                vetContactStatus: {},
                notesStatus: {},
                notificationSettingsStatus: {},
                alertStatus: {},
                userAccountStatus: {},
                toggleOnboarding: 0
            }
        },
        computed: {
            clientId() {
                return !!this.client ? this.client.id : '';
            },
            statuses() {
                return [
                    this.detailsStatus,
                    this.altContactStatus,
                    this.vetContactStatus,
                    this.notesStatus,
                    this.notificationSettingsStatus,
                    this.alertStatus,
                    this.userAccountStatus
                ];
            },
            status() {
                return {
                    loading: this.statuses.some(s => !!s.loading),
                    error: this.statuses.some(s => !!s.error)
                }
            }
        },
        components: {
            'collapsed-section': CollapsedSection,
            'tooltip': Tooltip,
            'client-details': ClientDetailsSection,
            'client-notes': ClientNotesSection,
            'client-notification-settings': ClientEditNotificationSettingsSection,
            'client-appointments': ClientAppointmentSection,
            'client-document': ClientDocumentSection,
            'client-alt-contact': ClientEditAltContactSection,
            'client-vet-contact': ClientEditVetContactSection,
            'client-alert': ClientAlertSection,
            'client-onboarding': ClientOnboardingInviteSection,
            'client-logs': ClientLogsSection,
            'client-user-account': ClientEditUserAccountSection,
            'payment-customer': PaymentCustomers,
            'payment-instruments': PaymentInstruments
        }
    }
</script>
